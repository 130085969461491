export const male_basic = [{
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 240,
    "PayoutAmount2": 210,
    "PayoutAmount3": 180,
    "PSA": 65,
    "RAPSA": 40000,
    "EstimatedRACurrent": 40000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 270,
    "PayoutAmount2": 240,
    "PayoutAmount3": 210,
    "PSA": 65,
    "RAPSA": 45000,
    "EstimatedRACurrent": 45000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 300,
    "PayoutAmount2": 270,
    "PayoutAmount3": 230,
    "PSA": 65,
    "RAPSA": 50000,
    "EstimatedRACurrent": 50000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 320,
    "PayoutAmount2": 300,
    "PayoutAmount3": 260,
    "PSA": 65,
    "RAPSA": 55000,
    "EstimatedRACurrent": 55000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 350,
    "PayoutAmount2": 320,
    "PayoutAmount3": 280,
    "PSA": 65,
    "RAPSA": 60000,
    "EstimatedRACurrent": 60000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 380,
    "PayoutAmount2": 350,
    "PayoutAmount3": 300,
    "PSA": 65,
    "RAPSA": 65000,
    "EstimatedRACurrent": 65000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 400,
    "PayoutAmount2": 380,
    "PayoutAmount3": 330,
    "PSA": 65,
    "RAPSA": 70000,
    "EstimatedRACurrent": 70000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 420,
    "PayoutAmount2": 400,
    "PayoutAmount3": 350,
    "PSA": 65,
    "RAPSA": 75000,
    "EstimatedRACurrent": 75000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 450,
    "PayoutAmount2": 430,
    "PayoutAmount3": 370,
    "PSA": 65,
    "RAPSA": 80000,
    "EstimatedRACurrent": 80000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 470,
    "PayoutAmount2": 450,
    "PayoutAmount3": 400,
    "PSA": 65,
    "RAPSA": 85000,
    "EstimatedRACurrent": 85000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 490,
    "PayoutAmount2": 480,
    "PayoutAmount3": 420,
    "PSA": 65,
    "RAPSA": 90000,
    "EstimatedRACurrent": 90000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 520,
    "PayoutAmount2": 500,
    "PayoutAmount3": 450,
    "PSA": 65,
    "RAPSA": 95000,
    "EstimatedRACurrent": 95000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 540,
    "PayoutAmount2": 530,
    "PayoutAmount3": 470,
    "PSA": 65,
    "RAPSA": 100000,
    "EstimatedRACurrent": 100000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 570,
    "PayoutAmount2": 560,
    "PayoutAmount3": 490,
    "PSA": 65,
    "RAPSA": 105000,
    "EstimatedRACurrent": 105000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 590,
    "PayoutAmount2": 580,
    "PayoutAmount3": 520,
    "PSA": 65,
    "RAPSA": 110000,
    "EstimatedRACurrent": 110000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 610,
    "PayoutAmount2": 610,
    "PayoutAmount3": 540,
    "PSA": 65,
    "RAPSA": 115000,
    "EstimatedRACurrent": 115000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 640,
    "PayoutAmount2": 630,
    "PayoutAmount3": 560,
    "PSA": 65,
    "RAPSA": 120000,
    "EstimatedRACurrent": 120000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 660,
    "PayoutAmount2": 660,
    "PayoutAmount3": 590,
    "PSA": 65,
    "RAPSA": 125000,
    "EstimatedRACurrent": 125000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 680,
    "PayoutAmount2": 680,
    "PayoutAmount3": 610,
    "PSA": 65,
    "RAPSA": 130000,
    "EstimatedRACurrent": 130000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 710,
    "PayoutAmount2": 710,
    "PayoutAmount3": 640,
    "PSA": 65,
    "RAPSA": 135000,
    "EstimatedRACurrent": 135000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 730,
    "PayoutAmount2": 730,
    "PayoutAmount3": 660,
    "PSA": 65,
    "RAPSA": 140000,
    "EstimatedRACurrent": 140000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 760,
    "PayoutAmount2": 760,
    "PayoutAmount3": 680,
    "PSA": 65,
    "RAPSA": 145000,
    "EstimatedRACurrent": 145000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 780,
    "PayoutAmount2": 780,
    "PayoutAmount3": 710,
    "PSA": 65,
    "RAPSA": 150000,
    "EstimatedRACurrent": 150000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 800,
    "PayoutAmount2": 800,
    "PayoutAmount3": 730,
    "PSA": 65,
    "RAPSA": 155000,
    "EstimatedRACurrent": 155000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 830,
    "PayoutAmount2": 830,
    "PayoutAmount3": 760,
    "PSA": 65,
    "RAPSA": 160000,
    "EstimatedRACurrent": 160000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 850,
    "PayoutAmount2": 850,
    "PayoutAmount3": 780,
    "PSA": 65,
    "RAPSA": 165000,
    "EstimatedRACurrent": 165000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 880,
    "PayoutAmount2": 880,
    "PayoutAmount3": 800,
    "PSA": 65,
    "RAPSA": 170000,
    "EstimatedRACurrent": 170000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 900,
    "PayoutAmount2": 900,
    "PayoutAmount3": 830,
    "PSA": 65,
    "RAPSA": 175000,
    "EstimatedRACurrent": 175000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 920,
    "PayoutAmount2": 920,
    "PayoutAmount3": 850,
    "PSA": 65,
    "RAPSA": 180000,
    "EstimatedRACurrent": 180000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 950,
    "PayoutAmount2": 950,
    "PayoutAmount3": 870,
    "PSA": 65,
    "RAPSA": 185000,
    "EstimatedRACurrent": 185000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 970,
    "PayoutAmount2": 970,
    "PayoutAmount3": 900,
    "PSA": 65,
    "RAPSA": 190000,
    "EstimatedRACurrent": 190000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 990,
    "PayoutAmount2": 990,
    "PayoutAmount3": 920,
    "PSA": 65,
    "RAPSA": 195000,
    "EstimatedRACurrent": 195000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1020,
    "PayoutAmount2": 1020,
    "PayoutAmount3": 950,
    "PSA": 65,
    "RAPSA": 200000,
    "EstimatedRACurrent": 200000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1040,
    "PayoutAmount2": 1040,
    "PayoutAmount3": 970,
    "PSA": 65,
    "RAPSA": 205000,
    "EstimatedRACurrent": 205000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1070,
    "PayoutAmount2": 1070,
    "PayoutAmount3": 990,
    "PSA": 65,
    "RAPSA": 210000,
    "EstimatedRACurrent": 210000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1090,
    "PayoutAmount2": 1090,
    "PayoutAmount3": 1020,
    "PSA": 65,
    "RAPSA": 215000,
    "EstimatedRACurrent": 215000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1110,
    "PayoutAmount2": 1110,
    "PayoutAmount3": 1040,
    "PSA": 65,
    "RAPSA": 220000,
    "EstimatedRACurrent": 220000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1140,
    "PayoutAmount2": 1140,
    "PayoutAmount3": 1060,
    "PSA": 65,
    "RAPSA": 225000,
    "EstimatedRACurrent": 225000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1160,
    "PayoutAmount2": 1160,
    "PayoutAmount3": 1090,
    "PSA": 65,
    "RAPSA": 230000,
    "EstimatedRACurrent": 230000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1180,
    "PayoutAmount2": 1180,
    "PayoutAmount3": 1110,
    "PSA": 65,
    "RAPSA": 235000,
    "EstimatedRACurrent": 235000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1210,
    "PayoutAmount2": 1210,
    "PayoutAmount3": 1140,
    "PSA": 65,
    "RAPSA": 240000,
    "EstimatedRACurrent": 240000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1230,
    "PayoutAmount2": 1230,
    "PayoutAmount3": 1160,
    "PSA": 65,
    "RAPSA": 245000,
    "EstimatedRACurrent": 245000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1260,
    "PayoutAmount2": 1260,
    "PayoutAmount3": 1180,
    "PSA": 65,
    "RAPSA": 250000,
    "EstimatedRACurrent": 250000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1280,
    "PayoutAmount2": 1280,
    "PayoutAmount3": 1210,
    "PSA": 65,
    "RAPSA": 255000,
    "EstimatedRACurrent": 255000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1300,
    "PayoutAmount2": 1300,
    "PayoutAmount3": 1230,
    "PSA": 65,
    "RAPSA": 260000,
    "EstimatedRACurrent": 260000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1330,
    "PayoutAmount2": 1330,
    "PayoutAmount3": 1260,
    "PSA": 65,
    "RAPSA": 265000,
    "EstimatedRACurrent": 265000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1350,
    "PayoutAmount2": 1350,
    "PayoutAmount3": 1280,
    "PSA": 65,
    "RAPSA": 270000,
    "EstimatedRACurrent": 270000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1380,
    "PayoutAmount2": 1380,
    "PayoutAmount3": 1300,
    "PSA": 65,
    "RAPSA": 275000,
    "EstimatedRACurrent": 275000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1400,
    "PayoutAmount2": 1400,
    "PayoutAmount3": 1330,
    "PSA": 65,
    "RAPSA": 280000,
    "EstimatedRACurrent": 280000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1420,
    "PayoutAmount2": 1420,
    "PayoutAmount3": 1350,
    "PSA": 65,
    "RAPSA": 285000,
    "EstimatedRACurrent": 285000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1450,
    "PayoutAmount2": 1450,
    "PayoutAmount3": 1370,
    "PSA": 65,
    "RAPSA": 290000,
    "EstimatedRACurrent": 290000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1470,
    "PayoutAmount2": 1470,
    "PayoutAmount3": 1400,
    "PSA": 65,
    "RAPSA": 295000,
    "EstimatedRACurrent": 295000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1490,
    "PayoutAmount2": 1490,
    "PayoutAmount3": 1420,
    "PSA": 65,
    "RAPSA": 300000,
    "EstimatedRACurrent": 300000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1520,
    "PayoutAmount2": 1520,
    "PayoutAmount3": 1450,
    "PSA": 65,
    "RAPSA": 305000,
    "EstimatedRACurrent": 305000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1540,
    "PayoutAmount2": 1540,
    "PayoutAmount3": 1470,
    "PSA": 65,
    "RAPSA": 310000,
    "EstimatedRACurrent": 310000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1570,
    "PayoutAmount2": 1570,
    "PayoutAmount3": 1490,
    "PSA": 65,
    "RAPSA": 315000,
    "EstimatedRACurrent": 315000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1590,
    "PayoutAmount2": 1590,
    "PayoutAmount3": 1520,
    "PSA": 65,
    "RAPSA": 320000,
    "EstimatedRACurrent": 320000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1610,
    "PayoutAmount2": 1610,
    "PayoutAmount3": 1540,
    "PSA": 65,
    "RAPSA": 325000,
    "EstimatedRACurrent": 325000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1640,
    "PayoutAmount2": 1640,
    "PayoutAmount3": 1560,
    "PSA": 65,
    "RAPSA": 330000,
    "EstimatedRACurrent": 330000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1660,
    "PayoutAmount2": 1660,
    "PayoutAmount3": 1590,
    "PSA": 65,
    "RAPSA": 335000,
    "EstimatedRACurrent": 335000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1680,
    "PayoutAmount2": 1680,
    "PayoutAmount3": 1610,
    "PSA": 65,
    "RAPSA": 340000,
    "EstimatedRACurrent": 340000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1710,
    "PayoutAmount2": 1710,
    "PayoutAmount3": 1640,
    "PSA": 65,
    "RAPSA": 345000,
    "EstimatedRACurrent": 345000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1730,
    "PayoutAmount2": 1730,
    "PayoutAmount3": 1660,
    "PSA": 65,
    "RAPSA": 350000,
    "EstimatedRACurrent": 350000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1760,
    "PayoutAmount2": 1760,
    "PayoutAmount3": 1680,
    "PSA": 65,
    "RAPSA": 355000,
    "EstimatedRACurrent": 355000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1780,
    "PayoutAmount2": 1780,
    "PayoutAmount3": 1710,
    "PSA": 65,
    "RAPSA": 360000,
    "EstimatedRACurrent": 360000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1800,
    "PayoutAmount2": 1800,
    "PayoutAmount3": 1730,
    "PSA": 65,
    "RAPSA": 365000,
    "EstimatedRACurrent": 365000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1830,
    "PayoutAmount2": 1830,
    "PayoutAmount3": 1760,
    "PSA": 65,
    "RAPSA": 370000,
    "EstimatedRACurrent": 370000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1850,
    "PayoutAmount2": 1850,
    "PayoutAmount3": 1780,
    "PSA": 65,
    "RAPSA": 375000,
    "EstimatedRACurrent": 375000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1880,
    "PayoutAmount2": 1880,
    "PayoutAmount3": 1800,
    "PSA": 65,
    "RAPSA": 380000,
    "EstimatedRACurrent": 380000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1900,
    "PayoutAmount2": 1900,
    "PayoutAmount3": 1830,
    "PSA": 65,
    "RAPSA": 385000,
    "EstimatedRACurrent": 385000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1920,
    "PayoutAmount2": 1920,
    "PayoutAmount3": 1850,
    "PSA": 65,
    "RAPSA": 390000,
    "EstimatedRACurrent": 390000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1950,
    "PayoutAmount2": 1950,
    "PayoutAmount3": 1870,
    "PSA": 65,
    "RAPSA": 395000,
    "EstimatedRACurrent": 395000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1970,
    "PayoutAmount2": 1970,
    "PayoutAmount3": 1900,
    "PSA": 65,
    "RAPSA": 400000,
    "EstimatedRACurrent": 400000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1990,
    "PayoutAmount2": 1990,
    "PayoutAmount3": 1920,
    "PSA": 65,
    "RAPSA": 405000,
    "EstimatedRACurrent": 405000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2020,
    "PayoutAmount2": 2020,
    "PayoutAmount3": 1950,
    "PSA": 65,
    "RAPSA": 410000,
    "EstimatedRACurrent": 410000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2040,
    "PayoutAmount2": 2040,
    "PayoutAmount3": 1970,
    "PSA": 65,
    "RAPSA": 415000,
    "EstimatedRACurrent": 415000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2070,
    "PayoutAmount2": 2070,
    "PayoutAmount3": 1990,
    "PSA": 65,
    "RAPSA": 420000,
    "EstimatedRACurrent": 420000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2090,
    "PayoutAmount2": 2090,
    "PayoutAmount3": 2020,
    "PSA": 65,
    "RAPSA": 425000,
    "EstimatedRACurrent": 425000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2110,
    "PayoutAmount2": 2110,
    "PayoutAmount3": 2040,
    "PSA": 65,
    "RAPSA": 430000,
    "EstimatedRACurrent": 430000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2140,
    "PayoutAmount2": 2140,
    "PayoutAmount3": 2060,
    "PSA": 65,
    "RAPSA": 435000,
    "EstimatedRACurrent": 435000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2160,
    "PayoutAmount2": 2160,
    "PayoutAmount3": 2090,
    "PSA": 65,
    "RAPSA": 440000,
    "EstimatedRACurrent": 440000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2180,
    "PayoutAmount2": 2180,
    "PayoutAmount3": 2110,
    "PSA": 65,
    "RAPSA": 445000,
    "EstimatedRACurrent": 445000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2210,
    "PayoutAmount2": 2210,
    "PayoutAmount3": 2140,
    "PSA": 65,
    "RAPSA": 450000,
    "EstimatedRACurrent": 450000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2230,
    "PayoutAmount2": 2230,
    "PayoutAmount3": 2160,
    "PSA": 65,
    "RAPSA": 455000,
    "EstimatedRACurrent": 455000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2260,
    "PayoutAmount2": 2260,
    "PayoutAmount3": 2180,
    "PSA": 65,
    "RAPSA": 460000,
    "EstimatedRACurrent": 460000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2280,
    "PayoutAmount2": 2280,
    "PayoutAmount3": 2210,
    "PSA": 65,
    "RAPSA": 465000,
    "EstimatedRACurrent": 465000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2300,
    "PayoutAmount2": 2300,
    "PayoutAmount3": 2230,
    "PSA": 65,
    "RAPSA": 470000,
    "EstimatedRACurrent": 470000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2330,
    "PayoutAmount2": 2330,
    "PayoutAmount3": 2260,
    "PSA": 65,
    "RAPSA": 475000,
    "EstimatedRACurrent": 475000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2350,
    "PayoutAmount2": 2350,
    "PayoutAmount3": 2280,
    "PSA": 65,
    "RAPSA": 480000,
    "EstimatedRACurrent": 480000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2380,
    "PayoutAmount2": 2380,
    "PayoutAmount3": 2300,
    "PSA": 65,
    "RAPSA": 485000,
    "EstimatedRACurrent": 485000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2400,
    "PayoutAmount2": 2400,
    "PayoutAmount3": 2330,
    "PSA": 65,
    "RAPSA": 490000,
    "EstimatedRACurrent": 490000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2420,
    "PayoutAmount2": 2420,
    "PayoutAmount3": 2350,
    "PSA": 65,
    "RAPSA": 495000,
    "EstimatedRACurrent": 495000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2450,
    "PayoutAmount2": 2450,
    "PayoutAmount3": 2370,
    "PSA": 65,
    "RAPSA": 500000,
    "EstimatedRACurrent": 500000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2470,
    "PayoutAmount2": 2470,
    "PayoutAmount3": 2400,
    "PSA": 65,
    "RAPSA": 505000,
    "EstimatedRACurrent": 505000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2490,
    "PayoutAmount2": 2490,
    "PayoutAmount3": 2420,
    "PSA": 65,
    "RAPSA": 510000,
    "EstimatedRACurrent": 510000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2520,
    "PayoutAmount2": 2520,
    "PayoutAmount3": 2450,
    "PSA": 65,
    "RAPSA": 515000,
    "EstimatedRACurrent": 515000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2540,
    "PayoutAmount2": 2540,
    "PayoutAmount3": 2470,
    "PSA": 65,
    "RAPSA": 520000,
    "EstimatedRACurrent": 520000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2570,
    "PayoutAmount2": 2570,
    "PayoutAmount3": 2490,
    "PSA": 65,
    "RAPSA": 525000,
    "EstimatedRACurrent": 525000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2590,
    "PayoutAmount2": 2590,
    "PayoutAmount3": 2520,
    "PSA": 65,
    "RAPSA": 530000,
    "EstimatedRACurrent": 530000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2610,
    "PayoutAmount2": 2610,
    "PayoutAmount3": 2540,
    "PSA": 65,
    "RAPSA": 535000,
    "EstimatedRACurrent": 535000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2640,
    "PayoutAmount2": 2640,
    "PayoutAmount3": 2560,
    "PSA": 65,
    "RAPSA": 540000,
    "EstimatedRACurrent": 540000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2660,
    "PayoutAmount2": 2660,
    "PayoutAmount3": 2590,
    "PSA": 65,
    "RAPSA": 545000,
    "EstimatedRACurrent": 545000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2680,
    "PayoutAmount2": 2680,
    "PayoutAmount3": 2610,
    "PSA": 65,
    "RAPSA": 550000,
    "EstimatedRACurrent": 550000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2710,
    "PayoutAmount2": 2710,
    "PayoutAmount3": 2640,
    "PSA": 65,
    "RAPSA": 555000,
    "EstimatedRACurrent": 555000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2730,
    "PayoutAmount2": 2730,
    "PayoutAmount3": 2660,
    "PSA": 65,
    "RAPSA": 560000,
    "EstimatedRACurrent": 560000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2760,
    "PayoutAmount2": 2760,
    "PayoutAmount3": 2680,
    "PSA": 65,
    "RAPSA": 565000,
    "EstimatedRACurrent": 565000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2780,
    "PayoutAmount2": 2780,
    "PayoutAmount3": 2710,
    "PSA": 65,
    "RAPSA": 570000,
    "EstimatedRACurrent": 570000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2800,
    "PayoutAmount2": 2800,
    "PayoutAmount3": 2730,
    "PSA": 65,
    "RAPSA": 575000,
    "EstimatedRACurrent": 575000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2830,
    "PayoutAmount2": 2830,
    "PayoutAmount3": 2760,
    "PSA": 65,
    "RAPSA": 580000,
    "EstimatedRACurrent": 580000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2850,
    "PayoutAmount2": 2850,
    "PayoutAmount3": 2780,
    "PSA": 65,
    "RAPSA": 585000,
    "EstimatedRACurrent": 585000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2870,
    "PayoutAmount2": 2870,
    "PayoutAmount3": 2800,
    "PSA": 65,
    "RAPSA": 590000,
    "EstimatedRACurrent": 590000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2900,
    "PayoutAmount2": 2900,
    "PayoutAmount3": 2830,
    "PSA": 65,
    "RAPSA": 595000,
    "EstimatedRACurrent": 595000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2920,
    "PayoutAmount2": 2920,
    "PayoutAmount3": 2850,
    "PSA": 65,
    "RAPSA": 600000,
    "EstimatedRACurrent": 600000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2950,
    "PayoutAmount2": 2950,
    "PayoutAmount3": 2870,
    "PSA": 65,
    "RAPSA": 605000,
    "EstimatedRACurrent": 605000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2970,
    "PayoutAmount2": 2970,
    "PayoutAmount3": 2900,
    "PSA": 65,
    "RAPSA": 610000,
    "EstimatedRACurrent": 610000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2990,
    "PayoutAmount2": 2990,
    "PayoutAmount3": 2920,
    "PSA": 65,
    "RAPSA": 615000,
    "EstimatedRACurrent": 615000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3020,
    "PayoutAmount2": 3020,
    "PayoutAmount3": 2950,
    "PSA": 65,
    "RAPSA": 620000,
    "EstimatedRACurrent": 620000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3040,
    "PayoutAmount2": 3040,
    "PayoutAmount3": 2970,
    "PSA": 65,
    "RAPSA": 625000,
    "EstimatedRACurrent": 625000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3070,
    "PayoutAmount2": 3070,
    "PayoutAmount3": 2990,
    "PSA": 65,
    "RAPSA": 630000,
    "EstimatedRACurrent": 630000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3090,
    "PayoutAmount2": 3090,
    "PayoutAmount3": 3020,
    "PSA": 65,
    "RAPSA": 635000,
    "EstimatedRACurrent": 635000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3110,
    "PayoutAmount2": 3110,
    "PayoutAmount3": 3040,
    "PSA": 65,
    "RAPSA": 640000,
    "EstimatedRACurrent": 640000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3140,
    "PayoutAmount2": 3140,
    "PayoutAmount3": 3060,
    "PSA": 65,
    "RAPSA": 645000,
    "EstimatedRACurrent": 645000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3160,
    "PayoutAmount2": 3160,
    "PayoutAmount3": 3090,
    "PSA": 65,
    "RAPSA": 650000,
    "EstimatedRACurrent": 650000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3180,
    "PayoutAmount2": 3180,
    "PayoutAmount3": 3110,
    "PSA": 65,
    "RAPSA": 655000,
    "EstimatedRACurrent": 655000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3210,
    "PayoutAmount2": 3210,
    "PayoutAmount3": 3140,
    "PSA": 65,
    "RAPSA": 660000,
    "EstimatedRACurrent": 660000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3230,
    "PayoutAmount2": 3230,
    "PayoutAmount3": 3160,
    "PSA": 65,
    "RAPSA": 665000,
    "EstimatedRACurrent": 665000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3260,
    "PayoutAmount2": 3260,
    "PayoutAmount3": 3180,
    "PSA": 65,
    "RAPSA": 670000,
    "EstimatedRACurrent": 670000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3280,
    "PayoutAmount2": 3280,
    "PayoutAmount3": 3210,
    "PSA": 65,
    "RAPSA": 675000,
    "EstimatedRACurrent": 675000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3300,
    "PayoutAmount2": 3300,
    "PayoutAmount3": 3230,
    "PSA": 65,
    "RAPSA": 680000,
    "EstimatedRACurrent": 680000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3330,
    "PayoutAmount2": 3330,
    "PayoutAmount3": 3260,
    "PSA": 65,
    "RAPSA": 685000,
    "EstimatedRACurrent": 685000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3350,
    "PayoutAmount2": 3350,
    "PayoutAmount3": 3280,
    "PSA": 65,
    "RAPSA": 690000,
    "EstimatedRACurrent": 690000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3370,
    "PayoutAmount2": 3370,
    "PayoutAmount3": 3300,
    "PSA": 65,
    "RAPSA": 695000,
    "EstimatedRACurrent": 695000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3400,
    "PayoutAmount2": 3400,
    "PayoutAmount3": 3330,
    "PSA": 65,
    "RAPSA": 700000,
    "EstimatedRACurrent": 700000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3420,
    "PayoutAmount2": 3420,
    "PayoutAmount3": 3350,
    "PSA": 65,
    "RAPSA": 705000,
    "EstimatedRACurrent": 705000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3450,
    "PayoutAmount2": 3450,
    "PayoutAmount3": 3370,
    "PSA": 65,
    "RAPSA": 710000,
    "EstimatedRACurrent": 710000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3470,
    "PayoutAmount2": 3470,
    "PayoutAmount3": 3400,
    "PSA": 65,
    "RAPSA": 715000,
    "EstimatedRACurrent": 715000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3490,
    "PayoutAmount2": 3490,
    "PayoutAmount3": 3420,
    "PSA": 65,
    "RAPSA": 720000,
    "EstimatedRACurrent": 720000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3520,
    "PayoutAmount2": 3520,
    "PayoutAmount3": 3450,
    "PSA": 65,
    "RAPSA": 725000,
    "EstimatedRACurrent": 725000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3540,
    "PayoutAmount2": 3540,
    "PayoutAmount3": 3470,
    "PSA": 65,
    "RAPSA": 730000,
    "EstimatedRACurrent": 730000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3570,
    "PayoutAmount2": 3570,
    "PayoutAmount3": 3490,
    "PSA": 65,
    "RAPSA": 735000,
    "EstimatedRACurrent": 735000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3590,
    "PayoutAmount2": 3590,
    "PayoutAmount3": 3520,
    "PSA": 65,
    "RAPSA": 740000,
    "EstimatedRACurrent": 740000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3610,
    "PayoutAmount2": 3610,
    "PayoutAmount3": 3540,
    "PSA": 65,
    "RAPSA": 745000,
    "EstimatedRACurrent": 745000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3640,
    "PayoutAmount2": 3640,
    "PayoutAmount3": 3560,
    "PSA": 65,
    "RAPSA": 750000,
    "EstimatedRACurrent": 750000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3660,
    "PayoutAmount2": 3660,
    "PayoutAmount3": 3590,
    "PSA": 65,
    "RAPSA": 755000,
    "EstimatedRACurrent": 755000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3680,
    "PayoutAmount2": 3680,
    "PayoutAmount3": 3610,
    "PSA": 65,
    "RAPSA": 760000,
    "EstimatedRACurrent": 760000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3710,
    "PayoutAmount2": 3710,
    "PayoutAmount3": 3640,
    "PSA": 65,
    "RAPSA": 765000,
    "EstimatedRACurrent": 765000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3730,
    "PayoutAmount2": 3730,
    "PayoutAmount3": 3660,
    "PSA": 65,
    "RAPSA": 770000,
    "EstimatedRACurrent": 770000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3760,
    "PayoutAmount2": 3760,
    "PayoutAmount3": 3680,
    "PSA": 65,
    "RAPSA": 775000,
    "EstimatedRACurrent": 775000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3780,
    "PayoutAmount2": 3780,
    "PayoutAmount3": 3710,
    "PSA": 65,
    "RAPSA": 780000,
    "EstimatedRACurrent": 780000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3800,
    "PayoutAmount2": 3800,
    "PayoutAmount3": 3730,
    "PSA": 65,
    "RAPSA": 785000,
    "EstimatedRACurrent": 785000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3830,
    "PayoutAmount2": 3830,
    "PayoutAmount3": 3760,
    "PSA": 65,
    "RAPSA": 790000,
    "EstimatedRACurrent": 790000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3850,
    "PayoutAmount2": 3850,
    "PayoutAmount3": 3780,
    "PSA": 65,
    "RAPSA": 795000,
    "EstimatedRACurrent": 795000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3870,
    "PayoutAmount2": 3870,
    "PayoutAmount3": 3800,
    "PSA": 65,
    "RAPSA": 800000,
    "EstimatedRACurrent": 800000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "bd19927b-4790-4e07-9556-9990d9281ca5"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}];