import {male_standard} from "./payout_data/male_standard";
import {male_basic} from "./payout_data/male_basic";
import {male_escalating} from "./payout_data/male_escalating";
import {female_standard} from "./payout_data/female_standard";
import {female_basic} from "./payout_data/female_basic";
import {female_escalating} from "./payout_data/female_escalating";

const interpolate_payout = (startPayout, endPayout, startAge, endAge, currentAge) => {
    return startPayout + (endPayout - startPayout) / (endAge - startAge) * (currentAge - startAge);
}

export const generate_result = (gender, balance) => {

    const data = {
        male: {
            standard: male_standard,
            basic: male_basic,
            escalating: male_escalating
        },
        female: {
            standard: female_standard,
            basic: female_basic,
            escalating: female_escalating
        }
    }

    const result = {}

    for (const plan of ["standard", "basic", "escalating"]) {
        const payouts = data[gender][plan].find(item => item["EstimatedRACurrent"] === balance);
        const rows = [];

        let ap_balance = plan === "basic" ? 0.2 * balance : balance;
        let ra_balance = balance - ap_balance;

        let total_payout = 0;
        let last_year_payout = 0;

        const payout_65 = payouts["PayoutAmount1"];
        const payout_85 = payouts["PayoutAmount2"];
        const payout_95 = payouts["PayoutAmount3"];

        for (let age = 65; age <= 95; age++) {

            let monthly_payout = 0;

            // Get monthly payout based on age and plan
            if (age === 65 || plan === "standard" || (plan === "basic" && ra_balance > 60000))
                monthly_payout = payout_65;
            else if (age === 85)
                monthly_payout = payout_85;
            else if (age === 95)
                monthly_payout = payout_95;
            else if (age > 65 && age < 85)
                monthly_payout = interpolate_payout(payout_65, payout_85, 65, 85, age);
            else
                monthly_payout = interpolate_payout(payout_85, payout_95, 85, 95, age);

            // Deduct from AP if RA balance depleted
            ap_balance += Math.min(0, ra_balance - last_year_payout);

            // Update RA balance
            ra_balance = Math.max(0, ra_balance - last_year_payout) * 1.04;

            total_payout += last_year_payout;

            last_year_payout = monthly_payout * 12;

            const bequest = Math.max(0, ra_balance + ap_balance);

            rows.push({
                key: age,
                age: age,
                payout: monthly_payout,
                total_payout: total_payout,
                bequest: bequest,
                total: total_payout + bequest
            });
        }
        //console.log(rows)
        result[plan] = rows;
    }

    return result;
}