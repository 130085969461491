export const female_standard = [{
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 240,
    "PayoutAmount2": 240,
    "PayoutAmount3": 240,
    "PSA": 65,
    "RAPSA": 40000,
    "EstimatedRACurrent": 40000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 260,
    "PayoutAmount2": 260,
    "PayoutAmount3": 260,
    "PSA": 65,
    "RAPSA": 45000,
    "EstimatedRACurrent": 45000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 290,
    "PayoutAmount2": 290,
    "PayoutAmount3": 290,
    "PSA": 65,
    "RAPSA": 50000,
    "EstimatedRACurrent": 50000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 320,
    "PayoutAmount2": 320,
    "PayoutAmount3": 320,
    "PSA": 65,
    "RAPSA": 55000,
    "EstimatedRACurrent": 55000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 350,
    "PayoutAmount2": 350,
    "PayoutAmount3": 350,
    "PSA": 65,
    "RAPSA": 60000,
    "EstimatedRACurrent": 60000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 380,
    "PayoutAmount2": 380,
    "PayoutAmount3": 380,
    "PSA": 65,
    "RAPSA": 65000,
    "EstimatedRACurrent": 65000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 400,
    "PayoutAmount2": 400,
    "PayoutAmount3": 400,
    "PSA": 65,
    "RAPSA": 70000,
    "EstimatedRACurrent": 70000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 430,
    "PayoutAmount2": 430,
    "PayoutAmount3": 430,
    "PSA": 65,
    "RAPSA": 75000,
    "EstimatedRACurrent": 75000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 450,
    "PayoutAmount2": 450,
    "PayoutAmount3": 450,
    "PSA": 65,
    "RAPSA": 80000,
    "EstimatedRACurrent": 80000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 480,
    "PayoutAmount2": 480,
    "PayoutAmount3": 480,
    "PSA": 65,
    "RAPSA": 85000,
    "EstimatedRACurrent": 85000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 500,
    "PayoutAmount2": 500,
    "PayoutAmount3": 500,
    "PSA": 65,
    "RAPSA": 90000,
    "EstimatedRACurrent": 90000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 530,
    "PayoutAmount2": 530,
    "PayoutAmount3": 530,
    "PSA": 65,
    "RAPSA": 95000,
    "EstimatedRACurrent": 95000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 550,
    "PayoutAmount2": 550,
    "PayoutAmount3": 550,
    "PSA": 65,
    "RAPSA": 100000,
    "EstimatedRACurrent": 100000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 580,
    "PayoutAmount2": 580,
    "PayoutAmount3": 580,
    "PSA": 65,
    "RAPSA": 105000,
    "EstimatedRACurrent": 105000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 600,
    "PayoutAmount2": 600,
    "PayoutAmount3": 600,
    "PSA": 65,
    "RAPSA": 110000,
    "EstimatedRACurrent": 110000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 630,
    "PayoutAmount2": 630,
    "PayoutAmount3": 630,
    "PSA": 65,
    "RAPSA": 115000,
    "EstimatedRACurrent": 115000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 650,
    "PayoutAmount2": 650,
    "PayoutAmount3": 650,
    "PSA": 65,
    "RAPSA": 120000,
    "EstimatedRACurrent": 120000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 680,
    "PayoutAmount2": 680,
    "PayoutAmount3": 680,
    "PSA": 65,
    "RAPSA": 125000,
    "EstimatedRACurrent": 125000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 700,
    "PayoutAmount2": 700,
    "PayoutAmount3": 700,
    "PSA": 65,
    "RAPSA": 130000,
    "EstimatedRACurrent": 130000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 730,
    "PayoutAmount2": 730,
    "PayoutAmount3": 730,
    "PSA": 65,
    "RAPSA": 135000,
    "EstimatedRACurrent": 135000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 750,
    "PayoutAmount2": 750,
    "PayoutAmount3": 750,
    "PSA": 65,
    "RAPSA": 140000,
    "EstimatedRACurrent": 140000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 780,
    "PayoutAmount2": 780,
    "PayoutAmount3": 780,
    "PSA": 65,
    "RAPSA": 145000,
    "EstimatedRACurrent": 145000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 800,
    "PayoutAmount2": 800,
    "PayoutAmount3": 800,
    "PSA": 65,
    "RAPSA": 150000,
    "EstimatedRACurrent": 150000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 820,
    "PayoutAmount2": 820,
    "PayoutAmount3": 820,
    "PSA": 65,
    "RAPSA": 155000,
    "EstimatedRACurrent": 155000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 850,
    "PayoutAmount2": 850,
    "PayoutAmount3": 850,
    "PSA": 65,
    "RAPSA": 160000,
    "EstimatedRACurrent": 160000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 870,
    "PayoutAmount2": 870,
    "PayoutAmount3": 870,
    "PSA": 65,
    "RAPSA": 165000,
    "EstimatedRACurrent": 165000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 900,
    "PayoutAmount2": 900,
    "PayoutAmount3": 900,
    "PSA": 65,
    "RAPSA": 170000,
    "EstimatedRACurrent": 170000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 920,
    "PayoutAmount2": 920,
    "PayoutAmount3": 920,
    "PSA": 65,
    "RAPSA": 175000,
    "EstimatedRACurrent": 175000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 950,
    "PayoutAmount2": 950,
    "PayoutAmount3": 950,
    "PSA": 65,
    "RAPSA": 180000,
    "EstimatedRACurrent": 180000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 970,
    "PayoutAmount2": 970,
    "PayoutAmount3": 970,
    "PSA": 65,
    "RAPSA": 185000,
    "EstimatedRACurrent": 185000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 990,
    "PayoutAmount2": 990,
    "PayoutAmount3": 990,
    "PSA": 65,
    "RAPSA": 190000,
    "EstimatedRACurrent": 190000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1020,
    "PayoutAmount2": 1020,
    "PayoutAmount3": 1020,
    "PSA": 65,
    "RAPSA": 195000,
    "EstimatedRACurrent": 195000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1040,
    "PayoutAmount2": 1040,
    "PayoutAmount3": 1040,
    "PSA": 65,
    "RAPSA": 200000,
    "EstimatedRACurrent": 200000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1070,
    "PayoutAmount2": 1070,
    "PayoutAmount3": 1070,
    "PSA": 65,
    "RAPSA": 205000,
    "EstimatedRACurrent": 205000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1090,
    "PayoutAmount2": 1090,
    "PayoutAmount3": 1090,
    "PSA": 65,
    "RAPSA": 210000,
    "EstimatedRACurrent": 210000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1120,
    "PayoutAmount2": 1120,
    "PayoutAmount3": 1120,
    "PSA": 65,
    "RAPSA": 215000,
    "EstimatedRACurrent": 215000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1140,
    "PayoutAmount2": 1140,
    "PayoutAmount3": 1140,
    "PSA": 65,
    "RAPSA": 220000,
    "EstimatedRACurrent": 220000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1160,
    "PayoutAmount2": 1160,
    "PayoutAmount3": 1160,
    "PSA": 65,
    "RAPSA": 225000,
    "EstimatedRACurrent": 225000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1190,
    "PayoutAmount2": 1190,
    "PayoutAmount3": 1190,
    "PSA": 65,
    "RAPSA": 230000,
    "EstimatedRACurrent": 230000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1210,
    "PayoutAmount2": 1210,
    "PayoutAmount3": 1210,
    "PSA": 65,
    "RAPSA": 235000,
    "EstimatedRACurrent": 235000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1240,
    "PayoutAmount2": 1240,
    "PayoutAmount3": 1240,
    "PSA": 65,
    "RAPSA": 240000,
    "EstimatedRACurrent": 240000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1260,
    "PayoutAmount2": 1260,
    "PayoutAmount3": 1260,
    "PSA": 65,
    "RAPSA": 245000,
    "EstimatedRACurrent": 245000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1290,
    "PayoutAmount2": 1290,
    "PayoutAmount3": 1290,
    "PSA": 65,
    "RAPSA": 250000,
    "EstimatedRACurrent": 250000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1310,
    "PayoutAmount2": 1310,
    "PayoutAmount3": 1310,
    "PSA": 65,
    "RAPSA": 255000,
    "EstimatedRACurrent": 255000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1340,
    "PayoutAmount2": 1340,
    "PayoutAmount3": 1340,
    "PSA": 65,
    "RAPSA": 260000,
    "EstimatedRACurrent": 260000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1360,
    "PayoutAmount2": 1360,
    "PayoutAmount3": 1360,
    "PSA": 65,
    "RAPSA": 265000,
    "EstimatedRACurrent": 265000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1380,
    "PayoutAmount2": 1380,
    "PayoutAmount3": 1380,
    "PSA": 65,
    "RAPSA": 270000,
    "EstimatedRACurrent": 270000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1410,
    "PayoutAmount2": 1410,
    "PayoutAmount3": 1410,
    "PSA": 65,
    "RAPSA": 275000,
    "EstimatedRACurrent": 275000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1430,
    "PayoutAmount2": 1430,
    "PayoutAmount3": 1430,
    "PSA": 65,
    "RAPSA": 280000,
    "EstimatedRACurrent": 280000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1460,
    "PayoutAmount2": 1460,
    "PayoutAmount3": 1460,
    "PSA": 65,
    "RAPSA": 285000,
    "EstimatedRACurrent": 285000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1480,
    "PayoutAmount2": 1480,
    "PayoutAmount3": 1480,
    "PSA": 65,
    "RAPSA": 290000,
    "EstimatedRACurrent": 290000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1510,
    "PayoutAmount2": 1510,
    "PayoutAmount3": 1510,
    "PSA": 65,
    "RAPSA": 295000,
    "EstimatedRACurrent": 295000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1530,
    "PayoutAmount2": 1530,
    "PayoutAmount3": 1530,
    "PSA": 65,
    "RAPSA": 300000,
    "EstimatedRACurrent": 300000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1550,
    "PayoutAmount2": 1550,
    "PayoutAmount3": 1550,
    "PSA": 65,
    "RAPSA": 305000,
    "EstimatedRACurrent": 305000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1580,
    "PayoutAmount2": 1580,
    "PayoutAmount3": 1580,
    "PSA": 65,
    "RAPSA": 310000,
    "EstimatedRACurrent": 310000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1600,
    "PayoutAmount2": 1600,
    "PayoutAmount3": 1600,
    "PSA": 65,
    "RAPSA": 315000,
    "EstimatedRACurrent": 315000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1630,
    "PayoutAmount2": 1630,
    "PayoutAmount3": 1630,
    "PSA": 65,
    "RAPSA": 320000,
    "EstimatedRACurrent": 320000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1650,
    "PayoutAmount2": 1650,
    "PayoutAmount3": 1650,
    "PSA": 65,
    "RAPSA": 325000,
    "EstimatedRACurrent": 325000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1680,
    "PayoutAmount2": 1680,
    "PayoutAmount3": 1680,
    "PSA": 65,
    "RAPSA": 330000,
    "EstimatedRACurrent": 330000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1700,
    "PayoutAmount2": 1700,
    "PayoutAmount3": 1700,
    "PSA": 65,
    "RAPSA": 335000,
    "EstimatedRACurrent": 335000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1720,
    "PayoutAmount2": 1720,
    "PayoutAmount3": 1720,
    "PSA": 65,
    "RAPSA": 340000,
    "EstimatedRACurrent": 340000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1750,
    "PayoutAmount2": 1750,
    "PayoutAmount3": 1750,
    "PSA": 65,
    "RAPSA": 345000,
    "EstimatedRACurrent": 345000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1770,
    "PayoutAmount2": 1770,
    "PayoutAmount3": 1770,
    "PSA": 65,
    "RAPSA": 350000,
    "EstimatedRACurrent": 350000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1800,
    "PayoutAmount2": 1800,
    "PayoutAmount3": 1800,
    "PSA": 65,
    "RAPSA": 355000,
    "EstimatedRACurrent": 355000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1820,
    "PayoutAmount2": 1820,
    "PayoutAmount3": 1820,
    "PSA": 65,
    "RAPSA": 360000,
    "EstimatedRACurrent": 360000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1850,
    "PayoutAmount2": 1850,
    "PayoutAmount3": 1850,
    "PSA": 65,
    "RAPSA": 365000,
    "EstimatedRACurrent": 365000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1870,
    "PayoutAmount2": 1870,
    "PayoutAmount3": 1870,
    "PSA": 65,
    "RAPSA": 370000,
    "EstimatedRACurrent": 370000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1890,
    "PayoutAmount2": 1890,
    "PayoutAmount3": 1890,
    "PSA": 65,
    "RAPSA": 375000,
    "EstimatedRACurrent": 375000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1920,
    "PayoutAmount2": 1920,
    "PayoutAmount3": 1920,
    "PSA": 65,
    "RAPSA": 380000,
    "EstimatedRACurrent": 380000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1940,
    "PayoutAmount2": 1940,
    "PayoutAmount3": 1940,
    "PSA": 65,
    "RAPSA": 385000,
    "EstimatedRACurrent": 385000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1970,
    "PayoutAmount2": 1970,
    "PayoutAmount3": 1970,
    "PSA": 65,
    "RAPSA": 390000,
    "EstimatedRACurrent": 390000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1990,
    "PayoutAmount2": 1990,
    "PayoutAmount3": 1990,
    "PSA": 65,
    "RAPSA": 395000,
    "EstimatedRACurrent": 395000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2020,
    "PayoutAmount2": 2020,
    "PayoutAmount3": 2020,
    "PSA": 65,
    "RAPSA": 400000,
    "EstimatedRACurrent": 400000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2040,
    "PayoutAmount2": 2040,
    "PayoutAmount3": 2040,
    "PSA": 65,
    "RAPSA": 405000,
    "EstimatedRACurrent": 405000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2060,
    "PayoutAmount2": 2060,
    "PayoutAmount3": 2060,
    "PSA": 65,
    "RAPSA": 410000,
    "EstimatedRACurrent": 410000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2090,
    "PayoutAmount2": 2090,
    "PayoutAmount3": 2090,
    "PSA": 65,
    "RAPSA": 415000,
    "EstimatedRACurrent": 415000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2110,
    "PayoutAmount2": 2110,
    "PayoutAmount3": 2110,
    "PSA": 65,
    "RAPSA": 420000,
    "EstimatedRACurrent": 420000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2140,
    "PayoutAmount2": 2140,
    "PayoutAmount3": 2140,
    "PSA": 65,
    "RAPSA": 425000,
    "EstimatedRACurrent": 425000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2160,
    "PayoutAmount2": 2160,
    "PayoutAmount3": 2160,
    "PSA": 65,
    "RAPSA": 430000,
    "EstimatedRACurrent": 430000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2190,
    "PayoutAmount2": 2190,
    "PayoutAmount3": 2190,
    "PSA": 65,
    "RAPSA": 435000,
    "EstimatedRACurrent": 435000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2210,
    "PayoutAmount2": 2210,
    "PayoutAmount3": 2210,
    "PSA": 65,
    "RAPSA": 440000,
    "EstimatedRACurrent": 440000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2230,
    "PayoutAmount2": 2230,
    "PayoutAmount3": 2230,
    "PSA": 65,
    "RAPSA": 445000,
    "EstimatedRACurrent": 445000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2260,
    "PayoutAmount2": 2260,
    "PayoutAmount3": 2260,
    "PSA": 65,
    "RAPSA": 450000,
    "EstimatedRACurrent": 450000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2280,
    "PayoutAmount2": 2280,
    "PayoutAmount3": 2280,
    "PSA": 65,
    "RAPSA": 455000,
    "EstimatedRACurrent": 455000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2310,
    "PayoutAmount2": 2310,
    "PayoutAmount3": 2310,
    "PSA": 65,
    "RAPSA": 460000,
    "EstimatedRACurrent": 460000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2330,
    "PayoutAmount2": 2330,
    "PayoutAmount3": 2330,
    "PSA": 65,
    "RAPSA": 465000,
    "EstimatedRACurrent": 465000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2360,
    "PayoutAmount2": 2360,
    "PayoutAmount3": 2360,
    "PSA": 65,
    "RAPSA": 470000,
    "EstimatedRACurrent": 470000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2380,
    "PayoutAmount2": 2380,
    "PayoutAmount3": 2380,
    "PSA": 65,
    "RAPSA": 475000,
    "EstimatedRACurrent": 475000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2400,
    "PayoutAmount2": 2400,
    "PayoutAmount3": 2400,
    "PSA": 65,
    "RAPSA": 480000,
    "EstimatedRACurrent": 480000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2430,
    "PayoutAmount2": 2430,
    "PayoutAmount3": 2430,
    "PSA": 65,
    "RAPSA": 485000,
    "EstimatedRACurrent": 485000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2450,
    "PayoutAmount2": 2450,
    "PayoutAmount3": 2450,
    "PSA": 65,
    "RAPSA": 490000,
    "EstimatedRACurrent": 490000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2480,
    "PayoutAmount2": 2480,
    "PayoutAmount3": 2480,
    "PSA": 65,
    "RAPSA": 495000,
    "EstimatedRACurrent": 495000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2500,
    "PayoutAmount2": 2500,
    "PayoutAmount3": 2500,
    "PSA": 65,
    "RAPSA": 500000,
    "EstimatedRACurrent": 500000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2530,
    "PayoutAmount2": 2530,
    "PayoutAmount3": 2530,
    "PSA": 65,
    "RAPSA": 505000,
    "EstimatedRACurrent": 505000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2550,
    "PayoutAmount2": 2550,
    "PayoutAmount3": 2550,
    "PSA": 65,
    "RAPSA": 510000,
    "EstimatedRACurrent": 510000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2570,
    "PayoutAmount2": 2570,
    "PayoutAmount3": 2570,
    "PSA": 65,
    "RAPSA": 515000,
    "EstimatedRACurrent": 515000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2600,
    "PayoutAmount2": 2600,
    "PayoutAmount3": 2600,
    "PSA": 65,
    "RAPSA": 520000,
    "EstimatedRACurrent": 520000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2620,
    "PayoutAmount2": 2620,
    "PayoutAmount3": 2620,
    "PSA": 65,
    "RAPSA": 525000,
    "EstimatedRACurrent": 525000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2650,
    "PayoutAmount2": 2650,
    "PayoutAmount3": 2650,
    "PSA": 65,
    "RAPSA": 530000,
    "EstimatedRACurrent": 530000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2670,
    "PayoutAmount2": 2670,
    "PayoutAmount3": 2670,
    "PSA": 65,
    "RAPSA": 535000,
    "EstimatedRACurrent": 535000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2700,
    "PayoutAmount2": 2700,
    "PayoutAmount3": 2700,
    "PSA": 65,
    "RAPSA": 540000,
    "EstimatedRACurrent": 540000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2720,
    "PayoutAmount2": 2720,
    "PayoutAmount3": 2720,
    "PSA": 65,
    "RAPSA": 545000,
    "EstimatedRACurrent": 545000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2740,
    "PayoutAmount2": 2740,
    "PayoutAmount3": 2740,
    "PSA": 65,
    "RAPSA": 550000,
    "EstimatedRACurrent": 550000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2770,
    "PayoutAmount2": 2770,
    "PayoutAmount3": 2770,
    "PSA": 65,
    "RAPSA": 555000,
    "EstimatedRACurrent": 555000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2790,
    "PayoutAmount2": 2790,
    "PayoutAmount3": 2790,
    "PSA": 65,
    "RAPSA": 560000,
    "EstimatedRACurrent": 560000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2820,
    "PayoutAmount2": 2820,
    "PayoutAmount3": 2820,
    "PSA": 65,
    "RAPSA": 565000,
    "EstimatedRACurrent": 565000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2840,
    "PayoutAmount2": 2840,
    "PayoutAmount3": 2840,
    "PSA": 65,
    "RAPSA": 570000,
    "EstimatedRACurrent": 570000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2870,
    "PayoutAmount2": 2870,
    "PayoutAmount3": 2870,
    "PSA": 65,
    "RAPSA": 575000,
    "EstimatedRACurrent": 575000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2890,
    "PayoutAmount2": 2890,
    "PayoutAmount3": 2890,
    "PSA": 65,
    "RAPSA": 580000,
    "EstimatedRACurrent": 580000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2910,
    "PayoutAmount2": 2910,
    "PayoutAmount3": 2910,
    "PSA": 65,
    "RAPSA": 585000,
    "EstimatedRACurrent": 585000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2940,
    "PayoutAmount2": 2940,
    "PayoutAmount3": 2940,
    "PSA": 65,
    "RAPSA": 590000,
    "EstimatedRACurrent": 590000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2960,
    "PayoutAmount2": 2960,
    "PayoutAmount3": 2960,
    "PSA": 65,
    "RAPSA": 595000,
    "EstimatedRACurrent": 595000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2990,
    "PayoutAmount2": 2990,
    "PayoutAmount3": 2990,
    "PSA": 65,
    "RAPSA": 600000,
    "EstimatedRACurrent": 600000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3010,
    "PayoutAmount2": 3010,
    "PayoutAmount3": 3010,
    "PSA": 65,
    "RAPSA": 605000,
    "EstimatedRACurrent": 605000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3040,
    "PayoutAmount2": 3040,
    "PayoutAmount3": 3040,
    "PSA": 65,
    "RAPSA": 610000,
    "EstimatedRACurrent": 610000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3060,
    "PayoutAmount2": 3060,
    "PayoutAmount3": 3060,
    "PSA": 65,
    "RAPSA": 615000,
    "EstimatedRACurrent": 615000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3080,
    "PayoutAmount2": 3080,
    "PayoutAmount3": 3080,
    "PSA": 65,
    "RAPSA": 620000,
    "EstimatedRACurrent": 620000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3110,
    "PayoutAmount2": 3110,
    "PayoutAmount3": 3110,
    "PSA": 65,
    "RAPSA": 625000,
    "EstimatedRACurrent": 625000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3130,
    "PayoutAmount2": 3130,
    "PayoutAmount3": 3130,
    "PSA": 65,
    "RAPSA": 630000,
    "EstimatedRACurrent": 630000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3160,
    "PayoutAmount2": 3160,
    "PayoutAmount3": 3160,
    "PSA": 65,
    "RAPSA": 635000,
    "EstimatedRACurrent": 635000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3180,
    "PayoutAmount2": 3180,
    "PayoutAmount3": 3180,
    "PSA": 65,
    "RAPSA": 640000,
    "EstimatedRACurrent": 640000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3210,
    "PayoutAmount2": 3210,
    "PayoutAmount3": 3210,
    "PSA": 65,
    "RAPSA": 645000,
    "EstimatedRACurrent": 645000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3230,
    "PayoutAmount2": 3230,
    "PayoutAmount3": 3230,
    "PSA": 65,
    "RAPSA": 650000,
    "EstimatedRACurrent": 650000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3250,
    "PayoutAmount2": 3250,
    "PayoutAmount3": 3250,
    "PSA": 65,
    "RAPSA": 655000,
    "EstimatedRACurrent": 655000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3280,
    "PayoutAmount2": 3280,
    "PayoutAmount3": 3280,
    "PSA": 65,
    "RAPSA": 660000,
    "EstimatedRACurrent": 660000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3300,
    "PayoutAmount2": 3300,
    "PayoutAmount3": 3300,
    "PSA": 65,
    "RAPSA": 665000,
    "EstimatedRACurrent": 665000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3330,
    "PayoutAmount2": 3330,
    "PayoutAmount3": 3330,
    "PSA": 65,
    "RAPSA": 670000,
    "EstimatedRACurrent": 670000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3350,
    "PayoutAmount2": 3350,
    "PayoutAmount3": 3350,
    "PSA": 65,
    "RAPSA": 675000,
    "EstimatedRACurrent": 675000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3380,
    "PayoutAmount2": 3380,
    "PayoutAmount3": 3380,
    "PSA": 65,
    "RAPSA": 680000,
    "EstimatedRACurrent": 680000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3400,
    "PayoutAmount2": 3400,
    "PayoutAmount3": 3400,
    "PSA": 65,
    "RAPSA": 685000,
    "EstimatedRACurrent": 685000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3420,
    "PayoutAmount2": 3420,
    "PayoutAmount3": 3420,
    "PSA": 65,
    "RAPSA": 690000,
    "EstimatedRACurrent": 690000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3450,
    "PayoutAmount2": 3450,
    "PayoutAmount3": 3450,
    "PSA": 65,
    "RAPSA": 695000,
    "EstimatedRACurrent": 695000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3470,
    "PayoutAmount2": 3470,
    "PayoutAmount3": 3470,
    "PSA": 65,
    "RAPSA": 700000,
    "EstimatedRACurrent": 700000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3500,
    "PayoutAmount2": 3500,
    "PayoutAmount3": 3500,
    "PSA": 65,
    "RAPSA": 705000,
    "EstimatedRACurrent": 705000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3520,
    "PayoutAmount2": 3520,
    "PayoutAmount3": 3520,
    "PSA": 65,
    "RAPSA": 710000,
    "EstimatedRACurrent": 710000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3550,
    "PayoutAmount2": 3550,
    "PayoutAmount3": 3550,
    "PSA": 65,
    "RAPSA": 715000,
    "EstimatedRACurrent": 715000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3570,
    "PayoutAmount2": 3570,
    "PayoutAmount3": 3570,
    "PSA": 65,
    "RAPSA": 720000,
    "EstimatedRACurrent": 720000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3590,
    "PayoutAmount2": 3590,
    "PayoutAmount3": 3590,
    "PSA": 65,
    "RAPSA": 725000,
    "EstimatedRACurrent": 725000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3620,
    "PayoutAmount2": 3620,
    "PayoutAmount3": 3620,
    "PSA": 65,
    "RAPSA": 730000,
    "EstimatedRACurrent": 730000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3640,
    "PayoutAmount2": 3640,
    "PayoutAmount3": 3640,
    "PSA": 65,
    "RAPSA": 735000,
    "EstimatedRACurrent": 735000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3670,
    "PayoutAmount2": 3670,
    "PayoutAmount3": 3670,
    "PSA": 65,
    "RAPSA": 740000,
    "EstimatedRACurrent": 740000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3690,
    "PayoutAmount2": 3690,
    "PayoutAmount3": 3690,
    "PSA": 65,
    "RAPSA": 745000,
    "EstimatedRACurrent": 745000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3720,
    "PayoutAmount2": 3720,
    "PayoutAmount3": 3720,
    "PSA": 65,
    "RAPSA": 750000,
    "EstimatedRACurrent": 750000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3740,
    "PayoutAmount2": 3740,
    "PayoutAmount3": 3740,
    "PSA": 65,
    "RAPSA": 755000,
    "EstimatedRACurrent": 755000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3760,
    "PayoutAmount2": 3760,
    "PayoutAmount3": 3760,
    "PSA": 65,
    "RAPSA": 760000,
    "EstimatedRACurrent": 760000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3790,
    "PayoutAmount2": 3790,
    "PayoutAmount3": 3790,
    "PSA": 65,
    "RAPSA": 765000,
    "EstimatedRACurrent": 765000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3810,
    "PayoutAmount2": 3810,
    "PayoutAmount3": 3810,
    "PSA": 65,
    "RAPSA": 770000,
    "EstimatedRACurrent": 770000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3840,
    "PayoutAmount2": 3840,
    "PayoutAmount3": 3840,
    "PSA": 65,
    "RAPSA": 775000,
    "EstimatedRACurrent": 775000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3860,
    "PayoutAmount2": 3860,
    "PayoutAmount3": 3860,
    "PSA": 65,
    "RAPSA": 780000,
    "EstimatedRACurrent": 780000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3890,
    "PayoutAmount2": 3890,
    "PayoutAmount3": 3890,
    "PSA": 65,
    "RAPSA": 785000,
    "EstimatedRACurrent": 785000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3910,
    "PayoutAmount2": 3910,
    "PayoutAmount3": 3910,
    "PSA": 65,
    "RAPSA": 790000,
    "EstimatedRACurrent": 790000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3930,
    "PayoutAmount2": 3930,
    "PayoutAmount3": 3930,
    "PSA": 65,
    "RAPSA": 795000,
    "EstimatedRACurrent": 795000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3960,
    "PayoutAmount2": 3960,
    "PayoutAmount3": 3960,
    "PSA": 65,
    "RAPSA": 800000,
    "EstimatedRACurrent": 800000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "eb24c52a-b3f8-4259-b818-255dd33b21ec"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}];