export const female_escalating = [{
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 190,
    "PayoutAmount2": 280,
    "PayoutAmount3": 340,
    "PSA": 65,
    "RAPSA": 40000,
    "EstimatedRACurrent": 40000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 210,
    "PayoutAmount2": 310,
    "PayoutAmount3": 380,
    "PSA": 65,
    "RAPSA": 45000,
    "EstimatedRACurrent": 45000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 230,
    "PayoutAmount2": 350,
    "PayoutAmount3": 420,
    "PSA": 65,
    "RAPSA": 50000,
    "EstimatedRACurrent": 50000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 250,
    "PayoutAmount2": 380,
    "PayoutAmount3": 460,
    "PSA": 65,
    "RAPSA": 55000,
    "EstimatedRACurrent": 55000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 270,
    "PayoutAmount2": 410,
    "PayoutAmount3": 500,
    "PSA": 65,
    "RAPSA": 60000,
    "EstimatedRACurrent": 60000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 300,
    "PayoutAmount2": 440,
    "PayoutAmount3": 540,
    "PSA": 65,
    "RAPSA": 65000,
    "EstimatedRACurrent": 65000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 320,
    "PayoutAmount2": 470,
    "PayoutAmount3": 570,
    "PSA": 65,
    "RAPSA": 70000,
    "EstimatedRACurrent": 70000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 330,
    "PayoutAmount2": 500,
    "PayoutAmount3": 610,
    "PSA": 65,
    "RAPSA": 75000,
    "EstimatedRACurrent": 75000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 350,
    "PayoutAmount2": 530,
    "PayoutAmount3": 650,
    "PSA": 65,
    "RAPSA": 80000,
    "EstimatedRACurrent": 80000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 370,
    "PayoutAmount2": 560,
    "PayoutAmount3": 680,
    "PSA": 65,
    "RAPSA": 85000,
    "EstimatedRACurrent": 85000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 390,
    "PayoutAmount2": 590,
    "PayoutAmount3": 720,
    "PSA": 65,
    "RAPSA": 90000,
    "EstimatedRACurrent": 90000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 410,
    "PayoutAmount2": 610,
    "PayoutAmount3": 750,
    "PSA": 65,
    "RAPSA": 95000,
    "EstimatedRACurrent": 95000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 430,
    "PayoutAmount2": 640,
    "PayoutAmount3": 780,
    "PSA": 65,
    "RAPSA": 100000,
    "EstimatedRACurrent": 100000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 450,
    "PayoutAmount2": 670,
    "PayoutAmount3": 820,
    "PSA": 65,
    "RAPSA": 105000,
    "EstimatedRACurrent": 105000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 470,
    "PayoutAmount2": 700,
    "PayoutAmount3": 850,
    "PSA": 65,
    "RAPSA": 110000,
    "EstimatedRACurrent": 110000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 490,
    "PayoutAmount2": 730,
    "PayoutAmount3": 890,
    "PSA": 65,
    "RAPSA": 115000,
    "EstimatedRACurrent": 115000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 510,
    "PayoutAmount2": 750,
    "PayoutAmount3": 920,
    "PSA": 65,
    "RAPSA": 120000,
    "EstimatedRACurrent": 120000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 530,
    "PayoutAmount2": 780,
    "PayoutAmount3": 960,
    "PSA": 65,
    "RAPSA": 125000,
    "EstimatedRACurrent": 125000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 540,
    "PayoutAmount2": 810,
    "PayoutAmount3": 990,
    "PSA": 65,
    "RAPSA": 130000,
    "EstimatedRACurrent": 130000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 560,
    "PayoutAmount2": 840,
    "PayoutAmount3": 1020,
    "PSA": 65,
    "RAPSA": 135000,
    "EstimatedRACurrent": 135000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 580,
    "PayoutAmount2": 870,
    "PayoutAmount3": 1060,
    "PSA": 65,
    "RAPSA": 140000,
    "EstimatedRACurrent": 140000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 600,
    "PayoutAmount2": 890,
    "PayoutAmount3": 1090,
    "PSA": 65,
    "RAPSA": 145000,
    "EstimatedRACurrent": 145000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 620,
    "PayoutAmount2": 920,
    "PayoutAmount3": 1130,
    "PSA": 65,
    "RAPSA": 150000,
    "EstimatedRACurrent": 150000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 640,
    "PayoutAmount2": 950,
    "PayoutAmount3": 1160,
    "PSA": 65,
    "RAPSA": 155000,
    "EstimatedRACurrent": 155000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 660,
    "PayoutAmount2": 980,
    "PayoutAmount3": 1190,
    "PSA": 65,
    "RAPSA": 160000,
    "EstimatedRACurrent": 160000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 680,
    "PayoutAmount2": 1010,
    "PayoutAmount3": 1230,
    "PSA": 65,
    "RAPSA": 165000,
    "EstimatedRACurrent": 165000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 690,
    "PayoutAmount2": 1030,
    "PayoutAmount3": 1260,
    "PSA": 65,
    "RAPSA": 170000,
    "EstimatedRACurrent": 170000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 710,
    "PayoutAmount2": 1060,
    "PayoutAmount3": 1300,
    "PSA": 65,
    "RAPSA": 175000,
    "EstimatedRACurrent": 175000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 730,
    "PayoutAmount2": 1090,
    "PayoutAmount3": 1330,
    "PSA": 65,
    "RAPSA": 180000,
    "EstimatedRACurrent": 180000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 750,
    "PayoutAmount2": 1120,
    "PayoutAmount3": 1360,
    "PSA": 65,
    "RAPSA": 185000,
    "EstimatedRACurrent": 185000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 770,
    "PayoutAmount2": 1150,
    "PayoutAmount3": 1400,
    "PSA": 65,
    "RAPSA": 190000,
    "EstimatedRACurrent": 190000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 790,
    "PayoutAmount2": 1170,
    "PayoutAmount3": 1430,
    "PSA": 65,
    "RAPSA": 195000,
    "EstimatedRACurrent": 195000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 810,
    "PayoutAmount2": 1200,
    "PayoutAmount3": 1470,
    "PSA": 65,
    "RAPSA": 200000,
    "EstimatedRACurrent": 200000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 830,
    "PayoutAmount2": 1230,
    "PayoutAmount3": 1500,
    "PSA": 65,
    "RAPSA": 205000,
    "EstimatedRACurrent": 205000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 850,
    "PayoutAmount2": 1260,
    "PayoutAmount3": 1530,
    "PSA": 65,
    "RAPSA": 210000,
    "EstimatedRACurrent": 210000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 860,
    "PayoutAmount2": 1290,
    "PayoutAmount3": 1570,
    "PSA": 65,
    "RAPSA": 215000,
    "EstimatedRACurrent": 215000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 880,
    "PayoutAmount2": 1310,
    "PayoutAmount3": 1600,
    "PSA": 65,
    "RAPSA": 220000,
    "EstimatedRACurrent": 220000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 900,
    "PayoutAmount2": 1340,
    "PayoutAmount3": 1640,
    "PSA": 65,
    "RAPSA": 225000,
    "EstimatedRACurrent": 225000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 920,
    "PayoutAmount2": 1370,
    "PayoutAmount3": 1670,
    "PSA": 65,
    "RAPSA": 230000,
    "EstimatedRACurrent": 230000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 940,
    "PayoutAmount2": 1400,
    "PayoutAmount3": 1710,
    "PSA": 65,
    "RAPSA": 235000,
    "EstimatedRACurrent": 235000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 960,
    "PayoutAmount2": 1430,
    "PayoutAmount3": 1740,
    "PSA": 65,
    "RAPSA": 240000,
    "EstimatedRACurrent": 240000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 980,
    "PayoutAmount2": 1450,
    "PayoutAmount3": 1770,
    "PSA": 65,
    "RAPSA": 245000,
    "EstimatedRACurrent": 245000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1000,
    "PayoutAmount2": 1480,
    "PayoutAmount3": 1810,
    "PSA": 65,
    "RAPSA": 250000,
    "EstimatedRACurrent": 250000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1010,
    "PayoutAmount2": 1510,
    "PayoutAmount3": 1840,
    "PSA": 65,
    "RAPSA": 255000,
    "EstimatedRACurrent": 255000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1030,
    "PayoutAmount2": 1540,
    "PayoutAmount3": 1880,
    "PSA": 65,
    "RAPSA": 260000,
    "EstimatedRACurrent": 260000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1050,
    "PayoutAmount2": 1570,
    "PayoutAmount3": 1910,
    "PSA": 65,
    "RAPSA": 265000,
    "EstimatedRACurrent": 265000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1070,
    "PayoutAmount2": 1590,
    "PayoutAmount3": 1940,
    "PSA": 65,
    "RAPSA": 270000,
    "EstimatedRACurrent": 270000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1090,
    "PayoutAmount2": 1620,
    "PayoutAmount3": 1980,
    "PSA": 65,
    "RAPSA": 275000,
    "EstimatedRACurrent": 275000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1110,
    "PayoutAmount2": 1650,
    "PayoutAmount3": 2010,
    "PSA": 65,
    "RAPSA": 280000,
    "EstimatedRACurrent": 280000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1130,
    "PayoutAmount2": 1680,
    "PayoutAmount3": 2050,
    "PSA": 65,
    "RAPSA": 285000,
    "EstimatedRACurrent": 285000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1150,
    "PayoutAmount2": 1710,
    "PayoutAmount3": 2080,
    "PSA": 65,
    "RAPSA": 290000,
    "EstimatedRACurrent": 290000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1160,
    "PayoutAmount2": 1730,
    "PayoutAmount3": 2110,
    "PSA": 65,
    "RAPSA": 295000,
    "EstimatedRACurrent": 295000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1180,
    "PayoutAmount2": 1760,
    "PayoutAmount3": 2150,
    "PSA": 65,
    "RAPSA": 300000,
    "EstimatedRACurrent": 300000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1200,
    "PayoutAmount2": 1790,
    "PayoutAmount3": 2180,
    "PSA": 65,
    "RAPSA": 305000,
    "EstimatedRACurrent": 305000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1220,
    "PayoutAmount2": 1820,
    "PayoutAmount3": 2220,
    "PSA": 65,
    "RAPSA": 310000,
    "EstimatedRACurrent": 310000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1240,
    "PayoutAmount2": 1850,
    "PayoutAmount3": 2250,
    "PSA": 65,
    "RAPSA": 315000,
    "EstimatedRACurrent": 315000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1260,
    "PayoutAmount2": 1870,
    "PayoutAmount3": 2280,
    "PSA": 65,
    "RAPSA": 320000,
    "EstimatedRACurrent": 320000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1280,
    "PayoutAmount2": 1900,
    "PayoutAmount3": 2320,
    "PSA": 65,
    "RAPSA": 325000,
    "EstimatedRACurrent": 325000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1300,
    "PayoutAmount2": 1930,
    "PayoutAmount3": 2350,
    "PSA": 65,
    "RAPSA": 330000,
    "EstimatedRACurrent": 330000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1320,
    "PayoutAmount2": 1960,
    "PayoutAmount3": 2390,
    "PSA": 65,
    "RAPSA": 335000,
    "EstimatedRACurrent": 335000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1330,
    "PayoutAmount2": 1980,
    "PayoutAmount3": 2420,
    "PSA": 65,
    "RAPSA": 340000,
    "EstimatedRACurrent": 340000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1350,
    "PayoutAmount2": 2010,
    "PayoutAmount3": 2450,
    "PSA": 65,
    "RAPSA": 345000,
    "EstimatedRACurrent": 345000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1370,
    "PayoutAmount2": 2040,
    "PayoutAmount3": 2490,
    "PSA": 65,
    "RAPSA": 350000,
    "EstimatedRACurrent": 350000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1390,
    "PayoutAmount2": 2070,
    "PayoutAmount3": 2520,
    "PSA": 65,
    "RAPSA": 355000,
    "EstimatedRACurrent": 355000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1410,
    "PayoutAmount2": 2100,
    "PayoutAmount3": 2560,
    "PSA": 65,
    "RAPSA": 360000,
    "EstimatedRACurrent": 360000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1430,
    "PayoutAmount2": 2120,
    "PayoutAmount3": 2590,
    "PSA": 65,
    "RAPSA": 365000,
    "EstimatedRACurrent": 365000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1450,
    "PayoutAmount2": 2150,
    "PayoutAmount3": 2620,
    "PSA": 65,
    "RAPSA": 370000,
    "EstimatedRACurrent": 370000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1470,
    "PayoutAmount2": 2180,
    "PayoutAmount3": 2660,
    "PSA": 65,
    "RAPSA": 375000,
    "EstimatedRACurrent": 375000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1480,
    "PayoutAmount2": 2210,
    "PayoutAmount3": 2690,
    "PSA": 65,
    "RAPSA": 380000,
    "EstimatedRACurrent": 380000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1500,
    "PayoutAmount2": 2240,
    "PayoutAmount3": 2730,
    "PSA": 65,
    "RAPSA": 385000,
    "EstimatedRACurrent": 385000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1520,
    "PayoutAmount2": 2260,
    "PayoutAmount3": 2760,
    "PSA": 65,
    "RAPSA": 390000,
    "EstimatedRACurrent": 390000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1540,
    "PayoutAmount2": 2290,
    "PayoutAmount3": 2800,
    "PSA": 65,
    "RAPSA": 395000,
    "EstimatedRACurrent": 395000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1560,
    "PayoutAmount2": 2320,
    "PayoutAmount3": 2830,
    "PSA": 65,
    "RAPSA": 400000,
    "EstimatedRACurrent": 400000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1580,
    "PayoutAmount2": 2350,
    "PayoutAmount3": 2860,
    "PSA": 65,
    "RAPSA": 405000,
    "EstimatedRACurrent": 405000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1600,
    "PayoutAmount2": 2380,
    "PayoutAmount3": 2900,
    "PSA": 65,
    "RAPSA": 410000,
    "EstimatedRACurrent": 410000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1620,
    "PayoutAmount2": 2400,
    "PayoutAmount3": 2930,
    "PSA": 65,
    "RAPSA": 415000,
    "EstimatedRACurrent": 415000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1630,
    "PayoutAmount2": 2430,
    "PayoutAmount3": 2970,
    "PSA": 65,
    "RAPSA": 420000,
    "EstimatedRACurrent": 420000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1650,
    "PayoutAmount2": 2460,
    "PayoutAmount3": 3000,
    "PSA": 65,
    "RAPSA": 425000,
    "EstimatedRACurrent": 425000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1670,
    "PayoutAmount2": 2490,
    "PayoutAmount3": 3030,
    "PSA": 65,
    "RAPSA": 430000,
    "EstimatedRACurrent": 430000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1690,
    "PayoutAmount2": 2520,
    "PayoutAmount3": 3070,
    "PSA": 65,
    "RAPSA": 435000,
    "EstimatedRACurrent": 435000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1710,
    "PayoutAmount2": 2540,
    "PayoutAmount3": 3100,
    "PSA": 65,
    "RAPSA": 440000,
    "EstimatedRACurrent": 440000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1730,
    "PayoutAmount2": 2570,
    "PayoutAmount3": 3140,
    "PSA": 65,
    "RAPSA": 445000,
    "EstimatedRACurrent": 445000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1750,
    "PayoutAmount2": 2600,
    "PayoutAmount3": 3170,
    "PSA": 65,
    "RAPSA": 450000,
    "EstimatedRACurrent": 450000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1770,
    "PayoutAmount2": 2630,
    "PayoutAmount3": 3200,
    "PSA": 65,
    "RAPSA": 455000,
    "EstimatedRACurrent": 455000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1790,
    "PayoutAmount2": 2660,
    "PayoutAmount3": 3240,
    "PSA": 65,
    "RAPSA": 460000,
    "EstimatedRACurrent": 460000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1800,
    "PayoutAmount2": 2680,
    "PayoutAmount3": 3270,
    "PSA": 65,
    "RAPSA": 465000,
    "EstimatedRACurrent": 465000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1820,
    "PayoutAmount2": 2710,
    "PayoutAmount3": 3310,
    "PSA": 65,
    "RAPSA": 470000,
    "EstimatedRACurrent": 470000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1840,
    "PayoutAmount2": 2740,
    "PayoutAmount3": 3340,
    "PSA": 65,
    "RAPSA": 475000,
    "EstimatedRACurrent": 475000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1860,
    "PayoutAmount2": 2770,
    "PayoutAmount3": 3370,
    "PSA": 65,
    "RAPSA": 480000,
    "EstimatedRACurrent": 480000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1880,
    "PayoutAmount2": 2790,
    "PayoutAmount3": 3410,
    "PSA": 65,
    "RAPSA": 485000,
    "EstimatedRACurrent": 485000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1900,
    "PayoutAmount2": 2820,
    "PayoutAmount3": 3440,
    "PSA": 65,
    "RAPSA": 490000,
    "EstimatedRACurrent": 490000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1920,
    "PayoutAmount2": 2850,
    "PayoutAmount3": 3480,
    "PSA": 65,
    "RAPSA": 495000,
    "EstimatedRACurrent": 495000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1940,
    "PayoutAmount2": 2880,
    "PayoutAmount3": 3510,
    "PSA": 65,
    "RAPSA": 500000,
    "EstimatedRACurrent": 500000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1950,
    "PayoutAmount2": 2910,
    "PayoutAmount3": 3540,
    "PSA": 65,
    "RAPSA": 505000,
    "EstimatedRACurrent": 505000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1970,
    "PayoutAmount2": 2930,
    "PayoutAmount3": 3580,
    "PSA": 65,
    "RAPSA": 510000,
    "EstimatedRACurrent": 510000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 1990,
    "PayoutAmount2": 2960,
    "PayoutAmount3": 3610,
    "PSA": 65,
    "RAPSA": 515000,
    "EstimatedRACurrent": 515000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2010,
    "PayoutAmount2": 2990,
    "PayoutAmount3": 3650,
    "PSA": 65,
    "RAPSA": 520000,
    "EstimatedRACurrent": 520000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2030,
    "PayoutAmount2": 3020,
    "PayoutAmount3": 3680,
    "PSA": 65,
    "RAPSA": 525000,
    "EstimatedRACurrent": 525000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2050,
    "PayoutAmount2": 3050,
    "PayoutAmount3": 3710,
    "PSA": 65,
    "RAPSA": 530000,
    "EstimatedRACurrent": 530000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2070,
    "PayoutAmount2": 3070,
    "PayoutAmount3": 3750,
    "PSA": 65,
    "RAPSA": 535000,
    "EstimatedRACurrent": 535000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2090,
    "PayoutAmount2": 3100,
    "PayoutAmount3": 3780,
    "PSA": 65,
    "RAPSA": 540000,
    "EstimatedRACurrent": 540000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2100,
    "PayoutAmount2": 3130,
    "PayoutAmount3": 3820,
    "PSA": 65,
    "RAPSA": 545000,
    "EstimatedRACurrent": 545000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2120,
    "PayoutAmount2": 3160,
    "PayoutAmount3": 3850,
    "PSA": 65,
    "RAPSA": 550000,
    "EstimatedRACurrent": 550000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2140,
    "PayoutAmount2": 3190,
    "PayoutAmount3": 3880,
    "PSA": 65,
    "RAPSA": 555000,
    "EstimatedRACurrent": 555000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2160,
    "PayoutAmount2": 3210,
    "PayoutAmount3": 3920,
    "PSA": 65,
    "RAPSA": 560000,
    "EstimatedRACurrent": 560000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2180,
    "PayoutAmount2": 3240,
    "PayoutAmount3": 3950,
    "PSA": 65,
    "RAPSA": 565000,
    "EstimatedRACurrent": 565000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2200,
    "PayoutAmount2": 3270,
    "PayoutAmount3": 3990,
    "PSA": 65,
    "RAPSA": 570000,
    "EstimatedRACurrent": 570000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2220,
    "PayoutAmount2": 3300,
    "PayoutAmount3": 4020,
    "PSA": 65,
    "RAPSA": 575000,
    "EstimatedRACurrent": 575000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2240,
    "PayoutAmount2": 3330,
    "PayoutAmount3": 4060,
    "PSA": 65,
    "RAPSA": 580000,
    "EstimatedRACurrent": 580000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2260,
    "PayoutAmount2": 3350,
    "PayoutAmount3": 4090,
    "PSA": 65,
    "RAPSA": 585000,
    "EstimatedRACurrent": 585000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2270,
    "PayoutAmount2": 3380,
    "PayoutAmount3": 4120,
    "PSA": 65,
    "RAPSA": 590000,
    "EstimatedRACurrent": 590000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2290,
    "PayoutAmount2": 3410,
    "PayoutAmount3": 4160,
    "PSA": 65,
    "RAPSA": 595000,
    "EstimatedRACurrent": 595000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2310,
    "PayoutAmount2": 3440,
    "PayoutAmount3": 4190,
    "PSA": 65,
    "RAPSA": 600000,
    "EstimatedRACurrent": 600000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2330,
    "PayoutAmount2": 3470,
    "PayoutAmount3": 4230,
    "PSA": 65,
    "RAPSA": 605000,
    "EstimatedRACurrent": 605000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2350,
    "PayoutAmount2": 3490,
    "PayoutAmount3": 4260,
    "PSA": 65,
    "RAPSA": 610000,
    "EstimatedRACurrent": 610000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2370,
    "PayoutAmount2": 3520,
    "PayoutAmount3": 4290,
    "PSA": 65,
    "RAPSA": 615000,
    "EstimatedRACurrent": 615000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2390,
    "PayoutAmount2": 3550,
    "PayoutAmount3": 4330,
    "PSA": 65,
    "RAPSA": 620000,
    "EstimatedRACurrent": 620000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2410,
    "PayoutAmount2": 3580,
    "PayoutAmount3": 4360,
    "PSA": 65,
    "RAPSA": 625000,
    "EstimatedRACurrent": 625000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2420,
    "PayoutAmount2": 3610,
    "PayoutAmount3": 4400,
    "PSA": 65,
    "RAPSA": 630000,
    "EstimatedRACurrent": 630000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2440,
    "PayoutAmount2": 3630,
    "PayoutAmount3": 4430,
    "PSA": 65,
    "RAPSA": 635000,
    "EstimatedRACurrent": 635000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2460,
    "PayoutAmount2": 3660,
    "PayoutAmount3": 4460,
    "PSA": 65,
    "RAPSA": 640000,
    "EstimatedRACurrent": 640000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2480,
    "PayoutAmount2": 3690,
    "PayoutAmount3": 4500,
    "PSA": 65,
    "RAPSA": 645000,
    "EstimatedRACurrent": 645000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2500,
    "PayoutAmount2": 3720,
    "PayoutAmount3": 4530,
    "PSA": 65,
    "RAPSA": 650000,
    "EstimatedRACurrent": 650000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2520,
    "PayoutAmount2": 3740,
    "PayoutAmount3": 4570,
    "PSA": 65,
    "RAPSA": 655000,
    "EstimatedRACurrent": 655000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2540,
    "PayoutAmount2": 3770,
    "PayoutAmount3": 4600,
    "PSA": 65,
    "RAPSA": 660000,
    "EstimatedRACurrent": 660000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2560,
    "PayoutAmount2": 3800,
    "PayoutAmount3": 4630,
    "PSA": 65,
    "RAPSA": 665000,
    "EstimatedRACurrent": 665000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2570,
    "PayoutAmount2": 3830,
    "PayoutAmount3": 4670,
    "PSA": 65,
    "RAPSA": 670000,
    "EstimatedRACurrent": 670000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2590,
    "PayoutAmount2": 3860,
    "PayoutAmount3": 4700,
    "PSA": 65,
    "RAPSA": 675000,
    "EstimatedRACurrent": 675000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2610,
    "PayoutAmount2": 3880,
    "PayoutAmount3": 4740,
    "PSA": 65,
    "RAPSA": 680000,
    "EstimatedRACurrent": 680000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2630,
    "PayoutAmount2": 3910,
    "PayoutAmount3": 4770,
    "PSA": 65,
    "RAPSA": 685000,
    "EstimatedRACurrent": 685000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2650,
    "PayoutAmount2": 3940,
    "PayoutAmount3": 4800,
    "PSA": 65,
    "RAPSA": 690000,
    "EstimatedRACurrent": 690000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2670,
    "PayoutAmount2": 3970,
    "PayoutAmount3": 4840,
    "PSA": 65,
    "RAPSA": 695000,
    "EstimatedRACurrent": 695000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2690,
    "PayoutAmount2": 4000,
    "PayoutAmount3": 4870,
    "PSA": 65,
    "RAPSA": 700000,
    "EstimatedRACurrent": 700000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2710,
    "PayoutAmount2": 4020,
    "PayoutAmount3": 4910,
    "PSA": 65,
    "RAPSA": 705000,
    "EstimatedRACurrent": 705000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2730,
    "PayoutAmount2": 4050,
    "PayoutAmount3": 4940,
    "PSA": 65,
    "RAPSA": 710000,
    "EstimatedRACurrent": 710000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2740,
    "PayoutAmount2": 4080,
    "PayoutAmount3": 4970,
    "PSA": 65,
    "RAPSA": 715000,
    "EstimatedRACurrent": 715000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2760,
    "PayoutAmount2": 4110,
    "PayoutAmount3": 5010,
    "PSA": 65,
    "RAPSA": 720000,
    "EstimatedRACurrent": 720000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2780,
    "PayoutAmount2": 4140,
    "PayoutAmount3": 5040,
    "PSA": 65,
    "RAPSA": 725000,
    "EstimatedRACurrent": 725000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2800,
    "PayoutAmount2": 4160,
    "PayoutAmount3": 5080,
    "PSA": 65,
    "RAPSA": 730000,
    "EstimatedRACurrent": 730000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2820,
    "PayoutAmount2": 4190,
    "PayoutAmount3": 5110,
    "PSA": 65,
    "RAPSA": 735000,
    "EstimatedRACurrent": 735000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2840,
    "PayoutAmount2": 4220,
    "PayoutAmount3": 5140,
    "PSA": 65,
    "RAPSA": 740000,
    "EstimatedRACurrent": 740000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2860,
    "PayoutAmount2": 4250,
    "PayoutAmount3": 5180,
    "PSA": 65,
    "RAPSA": 745000,
    "EstimatedRACurrent": 745000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2880,
    "PayoutAmount2": 4280,
    "PayoutAmount3": 5210,
    "PSA": 65,
    "RAPSA": 750000,
    "EstimatedRACurrent": 750000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2890,
    "PayoutAmount2": 4300,
    "PayoutAmount3": 5250,
    "PSA": 65,
    "RAPSA": 755000,
    "EstimatedRACurrent": 755000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2910,
    "PayoutAmount2": 4330,
    "PayoutAmount3": 5280,
    "PSA": 65,
    "RAPSA": 760000,
    "EstimatedRACurrent": 760000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2930,
    "PayoutAmount2": 4360,
    "PayoutAmount3": 5320,
    "PSA": 65,
    "RAPSA": 765000,
    "EstimatedRACurrent": 765000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2950,
    "PayoutAmount2": 4390,
    "PayoutAmount3": 5350,
    "PSA": 65,
    "RAPSA": 770000,
    "EstimatedRACurrent": 770000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2970,
    "PayoutAmount2": 4420,
    "PayoutAmount3": 5380,
    "PSA": 65,
    "RAPSA": 775000,
    "EstimatedRACurrent": 775000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 2990,
    "PayoutAmount2": 4440,
    "PayoutAmount3": 5420,
    "PSA": 65,
    "RAPSA": 780000,
    "EstimatedRACurrent": 780000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3010,
    "PayoutAmount2": 4470,
    "PayoutAmount3": 5450,
    "PSA": 65,
    "RAPSA": 785000,
    "EstimatedRACurrent": 785000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3030,
    "PayoutAmount2": 4500,
    "PayoutAmount3": 5490,
    "PSA": 65,
    "RAPSA": 790000,
    "EstimatedRACurrent": 790000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3040,
    "PayoutAmount2": 4530,
    "PayoutAmount3": 5520,
    "PSA": 65,
    "RAPSA": 795000,
    "EstimatedRACurrent": 795000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}, {
    "PayoutAge1": 65,
    "PayoutAge2": 85,
    "PayoutAge3": 95,
    "PayoutAmount1": 3060,
    "PayoutAmount2": 4550,
    "PayoutAmount3": 5550,
    "PSA": 65,
    "RAPSA": 800000,
    "EstimatedRACurrent": 800000,
    "ServiceInformation": {
        "ApplicationName": 0,
        "CurrentStepNumber": 0,
        "Description": "-",
        "FormCompletionTime": null,
        "ImportantNotes": "-",
        "ImportantNotesVisibility": false,
        "IsTermsAndConditionsChecked": false,
        "ModuleName": "",
        "Prerequisite": "-",
        "PrerequisiteVisibility": false,
        "RateThisService": {
            "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
        },
        "ResourceFileInstance": null,
        "Steps": [],
        "TermType": 1,
        "DisclaimerType": 0,
        "TermsAndConditions": "-",
        "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
        "TermsAndConditionsAcceptanceVisibility": false,
        "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
        "TermsAndConditionsVisibility": false,
        "SelectedUseRequestDetails": false,
        "SelectedUseRequestDetailsVisibility": false,
        "MyInfoNotesVisibility": false,
        "AdditionalInformation": null
    },
    "CalculateButtonText": "Calculate",
    "ResultText": "Results",
    "DataContext": null,
    "RequestContext": {
        "RequestContextId": "6abe26df-e77c-49db-87c8-419a7768a840"
    },
    "UserContext": {
        "IPAddress": "42.60.160.211",
        "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
        "UserId": -1,
        "UserName": "Anonymous",
        "CpfAccountNumber": null,
        "MasterMemberId": 0,
        "Birthdate": "\/Date(-62135596800000)\/",
        "Age": 0,
        "Sex": null
    }
}];