export const male_standard = [{
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 250,
        "PayoutAmount2": 250,
        "PayoutAmount3": 250,
        "PSA": 65,
        "RAPSA": 40000,
        "EstimatedRACurrent": 40000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 280,
        "PayoutAmount2": 280,
        "PayoutAmount3": 280,
        "PSA": 65,
        "RAPSA": 45000,
        "EstimatedRACurrent": 45000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 310,
        "PayoutAmount2": 310,
        "PayoutAmount3": 310,
        "PSA": 65,
        "RAPSA": 50000,
        "EstimatedRACurrent": 50000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 340,
        "PayoutAmount2": 340,
        "PayoutAmount3": 340,
        "PSA": 65,
        "RAPSA": 55000,
        "EstimatedRACurrent": 55000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 370,
        "PayoutAmount2": 370,
        "PayoutAmount3": 370,
        "PSA": 65,
        "RAPSA": 60000,
        "EstimatedRACurrent": 60000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 400,
        "PayoutAmount2": 400,
        "PayoutAmount3": 400,
        "PSA": 65,
        "RAPSA": 65000,
        "EstimatedRACurrent": 65000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 430,
        "PayoutAmount2": 430,
        "PayoutAmount3": 430,
        "PSA": 65,
        "RAPSA": 70000,
        "EstimatedRACurrent": 70000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 460,
        "PayoutAmount2": 460,
        "PayoutAmount3": 460,
        "PSA": 65,
        "RAPSA": 75000,
        "EstimatedRACurrent": 75000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 480,
        "PayoutAmount2": 480,
        "PayoutAmount3": 480,
        "PSA": 65,
        "RAPSA": 80000,
        "EstimatedRACurrent": 80000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 510,
        "PayoutAmount2": 510,
        "PayoutAmount3": 510,
        "PSA": 65,
        "RAPSA": 85000,
        "EstimatedRACurrent": 85000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 540,
        "PayoutAmount2": 540,
        "PayoutAmount3": 540,
        "PSA": 65,
        "RAPSA": 90000,
        "EstimatedRACurrent": 90000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 570,
        "PayoutAmount2": 570,
        "PayoutAmount3": 570,
        "PSA": 65,
        "RAPSA": 95000,
        "EstimatedRACurrent": 95000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 590,
        "PayoutAmount2": 590,
        "PayoutAmount3": 590,
        "PSA": 65,
        "RAPSA": 100000,
        "EstimatedRACurrent": 100000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 620,
        "PayoutAmount2": 620,
        "PayoutAmount3": 620,
        "PSA": 65,
        "RAPSA": 105000,
        "EstimatedRACurrent": 105000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 650,
        "PayoutAmount2": 650,
        "PayoutAmount3": 650,
        "PSA": 65,
        "RAPSA": 110000,
        "EstimatedRACurrent": 110000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 670,
        "PayoutAmount2": 670,
        "PayoutAmount3": 670,
        "PSA": 65,
        "RAPSA": 115000,
        "EstimatedRACurrent": 115000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 700,
        "PayoutAmount2": 700,
        "PayoutAmount3": 700,
        "PSA": 65,
        "RAPSA": 120000,
        "EstimatedRACurrent": 120000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 730,
        "PayoutAmount2": 730,
        "PayoutAmount3": 730,
        "PSA": 65,
        "RAPSA": 125000,
        "EstimatedRACurrent": 125000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 750,
        "PayoutAmount2": 750,
        "PayoutAmount3": 750,
        "PSA": 65,
        "RAPSA": 130000,
        "EstimatedRACurrent": 130000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 780,
        "PayoutAmount2": 780,
        "PayoutAmount3": 780,
        "PSA": 65,
        "RAPSA": 135000,
        "EstimatedRACurrent": 135000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 810,
        "PayoutAmount2": 810,
        "PayoutAmount3": 810,
        "PSA": 65,
        "RAPSA": 140000,
        "EstimatedRACurrent": 140000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 830,
        "PayoutAmount2": 830,
        "PayoutAmount3": 830,
        "PSA": 65,
        "RAPSA": 145000,
        "EstimatedRACurrent": 145000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 860,
        "PayoutAmount2": 860,
        "PayoutAmount3": 860,
        "PSA": 65,
        "RAPSA": 150000,
        "EstimatedRACurrent": 150000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 880,
        "PayoutAmount2": 880,
        "PayoutAmount3": 880,
        "PSA": 65,
        "RAPSA": 155000,
        "EstimatedRACurrent": 155000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 910,
        "PayoutAmount2": 910,
        "PayoutAmount3": 910,
        "PSA": 65,
        "RAPSA": 160000,
        "EstimatedRACurrent": 160000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 940,
        "PayoutAmount2": 940,
        "PayoutAmount3": 940,
        "PSA": 65,
        "RAPSA": 165000,
        "EstimatedRACurrent": 165000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 960,
        "PayoutAmount2": 960,
        "PayoutAmount3": 960,
        "PSA": 65,
        "RAPSA": 170000,
        "EstimatedRACurrent": 170000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 990,
        "PayoutAmount2": 990,
        "PayoutAmount3": 990,
        "PSA": 65,
        "RAPSA": 175000,
        "EstimatedRACurrent": 175000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1020,
        "PayoutAmount2": 1020,
        "PayoutAmount3": 1020,
        "PSA": 65,
        "RAPSA": 180000,
        "EstimatedRACurrent": 180000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1040,
        "PayoutAmount2": 1040,
        "PayoutAmount3": 1040,
        "PSA": 65,
        "RAPSA": 185000,
        "EstimatedRACurrent": 185000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1070,
        "PayoutAmount2": 1070,
        "PayoutAmount3": 1070,
        "PSA": 65,
        "RAPSA": 190000,
        "EstimatedRACurrent": 190000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1090,
        "PayoutAmount2": 1090,
        "PayoutAmount3": 1090,
        "PSA": 65,
        "RAPSA": 195000,
        "EstimatedRACurrent": 195000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1120,
        "PayoutAmount2": 1120,
        "PayoutAmount3": 1120,
        "PSA": 65,
        "RAPSA": 200000,
        "EstimatedRACurrent": 200000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1150,
        "PayoutAmount2": 1150,
        "PayoutAmount3": 1150,
        "PSA": 65,
        "RAPSA": 205000,
        "EstimatedRACurrent": 205000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1170,
        "PayoutAmount2": 1170,
        "PayoutAmount3": 1170,
        "PSA": 65,
        "RAPSA": 210000,
        "EstimatedRACurrent": 210000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1200,
        "PayoutAmount2": 1200,
        "PayoutAmount3": 1200,
        "PSA": 65,
        "RAPSA": 215000,
        "EstimatedRACurrent": 215000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1230,
        "PayoutAmount2": 1230,
        "PayoutAmount3": 1230,
        "PSA": 65,
        "RAPSA": 220000,
        "EstimatedRACurrent": 220000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1250,
        "PayoutAmount2": 1250,
        "PayoutAmount3": 1250,
        "PSA": 65,
        "RAPSA": 225000,
        "EstimatedRACurrent": 225000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1280,
        "PayoutAmount2": 1280,
        "PayoutAmount3": 1280,
        "PSA": 65,
        "RAPSA": 230000,
        "EstimatedRACurrent": 230000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1300,
        "PayoutAmount2": 1300,
        "PayoutAmount3": 1300,
        "PSA": 65,
        "RAPSA": 235000,
        "EstimatedRACurrent": 235000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1330,
        "PayoutAmount2": 1330,
        "PayoutAmount3": 1330,
        "PSA": 65,
        "RAPSA": 240000,
        "EstimatedRACurrent": 240000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1360,
        "PayoutAmount2": 1360,
        "PayoutAmount3": 1360,
        "PSA": 65,
        "RAPSA": 245000,
        "EstimatedRACurrent": 245000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1380,
        "PayoutAmount2": 1380,
        "PayoutAmount3": 1380,
        "PSA": 65,
        "RAPSA": 250000,
        "EstimatedRACurrent": 250000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1410,
        "PayoutAmount2": 1410,
        "PayoutAmount3": 1410,
        "PSA": 65,
        "RAPSA": 255000,
        "EstimatedRACurrent": 255000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1430,
        "PayoutAmount2": 1430,
        "PayoutAmount3": 1430,
        "PSA": 65,
        "RAPSA": 260000,
        "EstimatedRACurrent": 260000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1460,
        "PayoutAmount2": 1460,
        "PayoutAmount3": 1460,
        "PSA": 65,
        "RAPSA": 265000,
        "EstimatedRACurrent": 265000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1490,
        "PayoutAmount2": 1490,
        "PayoutAmount3": 1490,
        "PSA": 65,
        "RAPSA": 270000,
        "EstimatedRACurrent": 270000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1510,
        "PayoutAmount2": 1510,
        "PayoutAmount3": 1510,
        "PSA": 65,
        "RAPSA": 275000,
        "EstimatedRACurrent": 275000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1540,
        "PayoutAmount2": 1540,
        "PayoutAmount3": 1540,
        "PSA": 65,
        "RAPSA": 280000,
        "EstimatedRACurrent": 280000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1570,
        "PayoutAmount2": 1570,
        "PayoutAmount3": 1570,
        "PSA": 65,
        "RAPSA": 285000,
        "EstimatedRACurrent": 285000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1590,
        "PayoutAmount2": 1590,
        "PayoutAmount3": 1590,
        "PSA": 65,
        "RAPSA": 290000,
        "EstimatedRACurrent": 290000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1620,
        "PayoutAmount2": 1620,
        "PayoutAmount3": 1620,
        "PSA": 65,
        "RAPSA": 295000,
        "EstimatedRACurrent": 295000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1640,
        "PayoutAmount2": 1640,
        "PayoutAmount3": 1640,
        "PSA": 65,
        "RAPSA": 300000,
        "EstimatedRACurrent": 300000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1670,
        "PayoutAmount2": 1670,
        "PayoutAmount3": 1670,
        "PSA": 65,
        "RAPSA": 305000,
        "EstimatedRACurrent": 305000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1700,
        "PayoutAmount2": 1700,
        "PayoutAmount3": 1700,
        "PSA": 65,
        "RAPSA": 310000,
        "EstimatedRACurrent": 310000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1720,
        "PayoutAmount2": 1720,
        "PayoutAmount3": 1720,
        "PSA": 65,
        "RAPSA": 315000,
        "EstimatedRACurrent": 315000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1750,
        "PayoutAmount2": 1750,
        "PayoutAmount3": 1750,
        "PSA": 65,
        "RAPSA": 320000,
        "EstimatedRACurrent": 320000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1780,
        "PayoutAmount2": 1780,
        "PayoutAmount3": 1780,
        "PSA": 65,
        "RAPSA": 325000,
        "EstimatedRACurrent": 325000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1800,
        "PayoutAmount2": 1800,
        "PayoutAmount3": 1800,
        "PSA": 65,
        "RAPSA": 330000,
        "EstimatedRACurrent": 330000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1830,
        "PayoutAmount2": 1830,
        "PayoutAmount3": 1830,
        "PSA": 65,
        "RAPSA": 335000,
        "EstimatedRACurrent": 335000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1850,
        "PayoutAmount2": 1850,
        "PayoutAmount3": 1850,
        "PSA": 65,
        "RAPSA": 340000,
        "EstimatedRACurrent": 340000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1880,
        "PayoutAmount2": 1880,
        "PayoutAmount3": 1880,
        "PSA": 65,
        "RAPSA": 345000,
        "EstimatedRACurrent": 345000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1910,
        "PayoutAmount2": 1910,
        "PayoutAmount3": 1910,
        "PSA": 65,
        "RAPSA": 350000,
        "EstimatedRACurrent": 350000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1930,
        "PayoutAmount2": 1930,
        "PayoutAmount3": 1930,
        "PSA": 65,
        "RAPSA": 355000,
        "EstimatedRACurrent": 355000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1960,
        "PayoutAmount2": 1960,
        "PayoutAmount3": 1960,
        "PSA": 65,
        "RAPSA": 360000,
        "EstimatedRACurrent": 360000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 1980,
        "PayoutAmount2": 1980,
        "PayoutAmount3": 1980,
        "PSA": 65,
        "RAPSA": 365000,
        "EstimatedRACurrent": 365000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2010,
        "PayoutAmount2": 2010,
        "PayoutAmount3": 2010,
        "PSA": 65,
        "RAPSA": 370000,
        "EstimatedRACurrent": 370000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2040,
        "PayoutAmount2": 2040,
        "PayoutAmount3": 2040,
        "PSA": 65,
        "RAPSA": 375000,
        "EstimatedRACurrent": 375000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2060,
        "PayoutAmount2": 2060,
        "PayoutAmount3": 2060,
        "PSA": 65,
        "RAPSA": 380000,
        "EstimatedRACurrent": 380000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2090,
        "PayoutAmount2": 2090,
        "PayoutAmount3": 2090,
        "PSA": 65,
        "RAPSA": 385000,
        "EstimatedRACurrent": 385000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2120,
        "PayoutAmount2": 2120,
        "PayoutAmount3": 2120,
        "PSA": 65,
        "RAPSA": 390000,
        "EstimatedRACurrent": 390000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2140,
        "PayoutAmount2": 2140,
        "PayoutAmount3": 2140,
        "PSA": 65,
        "RAPSA": 395000,
        "EstimatedRACurrent": 395000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2170,
        "PayoutAmount2": 2170,
        "PayoutAmount3": 2170,
        "PSA": 65,
        "RAPSA": 400000,
        "EstimatedRACurrent": 400000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2190,
        "PayoutAmount2": 2190,
        "PayoutAmount3": 2190,
        "PSA": 65,
        "RAPSA": 405000,
        "EstimatedRACurrent": 405000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2220,
        "PayoutAmount2": 2220,
        "PayoutAmount3": 2220,
        "PSA": 65,
        "RAPSA": 410000,
        "EstimatedRACurrent": 410000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2250,
        "PayoutAmount2": 2250,
        "PayoutAmount3": 2250,
        "PSA": 65,
        "RAPSA": 415000,
        "EstimatedRACurrent": 415000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2270,
        "PayoutAmount2": 2270,
        "PayoutAmount3": 2270,
        "PSA": 65,
        "RAPSA": 420000,
        "EstimatedRACurrent": 420000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2300,
        "PayoutAmount2": 2300,
        "PayoutAmount3": 2300,
        "PSA": 65,
        "RAPSA": 425000,
        "EstimatedRACurrent": 425000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2320,
        "PayoutAmount2": 2320,
        "PayoutAmount3": 2320,
        "PSA": 65,
        "RAPSA": 430000,
        "EstimatedRACurrent": 430000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2350,
        "PayoutAmount2": 2350,
        "PayoutAmount3": 2350,
        "PSA": 65,
        "RAPSA": 435000,
        "EstimatedRACurrent": 435000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2380,
        "PayoutAmount2": 2380,
        "PayoutAmount3": 2380,
        "PSA": 65,
        "RAPSA": 440000,
        "EstimatedRACurrent": 440000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2400,
        "PayoutAmount2": 2400,
        "PayoutAmount3": 2400,
        "PSA": 65,
        "RAPSA": 445000,
        "EstimatedRACurrent": 445000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2430,
        "PayoutAmount2": 2430,
        "PayoutAmount3": 2430,
        "PSA": 65,
        "RAPSA": 450000,
        "EstimatedRACurrent": 450000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2460,
        "PayoutAmount2": 2460,
        "PayoutAmount3": 2460,
        "PSA": 65,
        "RAPSA": 455000,
        "EstimatedRACurrent": 455000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2480,
        "PayoutAmount2": 2480,
        "PayoutAmount3": 2480,
        "PSA": 65,
        "RAPSA": 460000,
        "EstimatedRACurrent": 460000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2510,
        "PayoutAmount2": 2510,
        "PayoutAmount3": 2510,
        "PSA": 65,
        "RAPSA": 465000,
        "EstimatedRACurrent": 465000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2530,
        "PayoutAmount2": 2530,
        "PayoutAmount3": 2530,
        "PSA": 65,
        "RAPSA": 470000,
        "EstimatedRACurrent": 470000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2560,
        "PayoutAmount2": 2560,
        "PayoutAmount3": 2560,
        "PSA": 65,
        "RAPSA": 475000,
        "EstimatedRACurrent": 475000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2590,
        "PayoutAmount2": 2590,
        "PayoutAmount3": 2590,
        "PSA": 65,
        "RAPSA": 480000,
        "EstimatedRACurrent": 480000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2610,
        "PayoutAmount2": 2610,
        "PayoutAmount3": 2610,
        "PSA": 65,
        "RAPSA": 485000,
        "EstimatedRACurrent": 485000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2640,
        "PayoutAmount2": 2640,
        "PayoutAmount3": 2640,
        "PSA": 65,
        "RAPSA": 490000,
        "EstimatedRACurrent": 490000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2660,
        "PayoutAmount2": 2660,
        "PayoutAmount3": 2660,
        "PSA": 65,
        "RAPSA": 495000,
        "EstimatedRACurrent": 495000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2690,
        "PayoutAmount2": 2690,
        "PayoutAmount3": 2690,
        "PSA": 65,
        "RAPSA": 500000,
        "EstimatedRACurrent": 500000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2720,
        "PayoutAmount2": 2720,
        "PayoutAmount3": 2720,
        "PSA": 65,
        "RAPSA": 505000,
        "EstimatedRACurrent": 505000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2740,
        "PayoutAmount2": 2740,
        "PayoutAmount3": 2740,
        "PSA": 65,
        "RAPSA": 510000,
        "EstimatedRACurrent": 510000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2770,
        "PayoutAmount2": 2770,
        "PayoutAmount3": 2770,
        "PSA": 65,
        "RAPSA": 515000,
        "EstimatedRACurrent": 515000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2800,
        "PayoutAmount2": 2800,
        "PayoutAmount3": 2800,
        "PSA": 65,
        "RAPSA": 520000,
        "EstimatedRACurrent": 520000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2820,
        "PayoutAmount2": 2820,
        "PayoutAmount3": 2820,
        "PSA": 65,
        "RAPSA": 525000,
        "EstimatedRACurrent": 525000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2850,
        "PayoutAmount2": 2850,
        "PayoutAmount3": 2850,
        "PSA": 65,
        "RAPSA": 530000,
        "EstimatedRACurrent": 530000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2870,
        "PayoutAmount2": 2870,
        "PayoutAmount3": 2870,
        "PSA": 65,
        "RAPSA": 535000,
        "EstimatedRACurrent": 535000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2900,
        "PayoutAmount2": 2900,
        "PayoutAmount3": 2900,
        "PSA": 65,
        "RAPSA": 540000,
        "EstimatedRACurrent": 540000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2930,
        "PayoutAmount2": 2930,
        "PayoutAmount3": 2930,
        "PSA": 65,
        "RAPSA": 545000,
        "EstimatedRACurrent": 545000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2950,
        "PayoutAmount2": 2950,
        "PayoutAmount3": 2950,
        "PSA": 65,
        "RAPSA": 550000,
        "EstimatedRACurrent": 550000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 2980,
        "PayoutAmount2": 2980,
        "PayoutAmount3": 2980,
        "PSA": 65,
        "RAPSA": 555000,
        "EstimatedRACurrent": 555000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3000,
        "PayoutAmount2": 3000,
        "PayoutAmount3": 3000,
        "PSA": 65,
        "RAPSA": 560000,
        "EstimatedRACurrent": 560000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3030,
        "PayoutAmount2": 3030,
        "PayoutAmount3": 3030,
        "PSA": 65,
        "RAPSA": 565000,
        "EstimatedRACurrent": 565000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3060,
        "PayoutAmount2": 3060,
        "PayoutAmount3": 3060,
        "PSA": 65,
        "RAPSA": 570000,
        "EstimatedRACurrent": 570000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3080,
        "PayoutAmount2": 3080,
        "PayoutAmount3": 3080,
        "PSA": 65,
        "RAPSA": 575000,
        "EstimatedRACurrent": 575000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3110,
        "PayoutAmount2": 3110,
        "PayoutAmount3": 3110,
        "PSA": 65,
        "RAPSA": 580000,
        "EstimatedRACurrent": 580000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3140,
        "PayoutAmount2": 3140,
        "PayoutAmount3": 3140,
        "PSA": 65,
        "RAPSA": 585000,
        "EstimatedRACurrent": 585000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3160,
        "PayoutAmount2": 3160,
        "PayoutAmount3": 3160,
        "PSA": 65,
        "RAPSA": 590000,
        "EstimatedRACurrent": 590000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3190,
        "PayoutAmount2": 3190,
        "PayoutAmount3": 3190,
        "PSA": 65,
        "RAPSA": 595000,
        "EstimatedRACurrent": 595000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3210,
        "PayoutAmount2": 3210,
        "PayoutAmount3": 3210,
        "PSA": 65,
        "RAPSA": 600000,
        "EstimatedRACurrent": 600000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3240,
        "PayoutAmount2": 3240,
        "PayoutAmount3": 3240,
        "PSA": 65,
        "RAPSA": 605000,
        "EstimatedRACurrent": 605000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3270,
        "PayoutAmount2": 3270,
        "PayoutAmount3": 3270,
        "PSA": 65,
        "RAPSA": 610000,
        "EstimatedRACurrent": 610000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3290,
        "PayoutAmount2": 3290,
        "PayoutAmount3": 3290,
        "PSA": 65,
        "RAPSA": 615000,
        "EstimatedRACurrent": 615000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3320,
        "PayoutAmount2": 3320,
        "PayoutAmount3": 3320,
        "PSA": 65,
        "RAPSA": 620000,
        "EstimatedRACurrent": 620000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3340,
        "PayoutAmount2": 3340,
        "PayoutAmount3": 3340,
        "PSA": 65,
        "RAPSA": 625000,
        "EstimatedRACurrent": 625000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3370,
        "PayoutAmount2": 3370,
        "PayoutAmount3": 3370,
        "PSA": 65,
        "RAPSA": 630000,
        "EstimatedRACurrent": 630000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3400,
        "PayoutAmount2": 3400,
        "PayoutAmount3": 3400,
        "PSA": 65,
        "RAPSA": 635000,
        "EstimatedRACurrent": 635000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3420,
        "PayoutAmount2": 3420,
        "PayoutAmount3": 3420,
        "PSA": 65,
        "RAPSA": 640000,
        "EstimatedRACurrent": 640000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3450,
        "PayoutAmount2": 3450,
        "PayoutAmount3": 3450,
        "PSA": 65,
        "RAPSA": 645000,
        "EstimatedRACurrent": 645000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3480,
        "PayoutAmount2": 3480,
        "PayoutAmount3": 3480,
        "PSA": 65,
        "RAPSA": 650000,
        "EstimatedRACurrent": 650000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3500,
        "PayoutAmount2": 3500,
        "PayoutAmount3": 3500,
        "PSA": 65,
        "RAPSA": 655000,
        "EstimatedRACurrent": 655000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3530,
        "PayoutAmount2": 3530,
        "PayoutAmount3": 3530,
        "PSA": 65,
        "RAPSA": 660000,
        "EstimatedRACurrent": 660000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3550,
        "PayoutAmount2": 3550,
        "PayoutAmount3": 3550,
        "PSA": 65,
        "RAPSA": 665000,
        "EstimatedRACurrent": 665000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3580,
        "PayoutAmount2": 3580,
        "PayoutAmount3": 3580,
        "PSA": 65,
        "RAPSA": 670000,
        "EstimatedRACurrent": 670000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3610,
        "PayoutAmount2": 3610,
        "PayoutAmount3": 3610,
        "PSA": 65,
        "RAPSA": 675000,
        "EstimatedRACurrent": 675000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3630,
        "PayoutAmount2": 3630,
        "PayoutAmount3": 3630,
        "PSA": 65,
        "RAPSA": 680000,
        "EstimatedRACurrent": 680000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3660,
        "PayoutAmount2": 3660,
        "PayoutAmount3": 3660,
        "PSA": 65,
        "RAPSA": 685000,
        "EstimatedRACurrent": 685000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3680,
        "PayoutAmount2": 3680,
        "PayoutAmount3": 3680,
        "PSA": 65,
        "RAPSA": 690000,
        "EstimatedRACurrent": 690000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3710,
        "PayoutAmount2": 3710,
        "PayoutAmount3": 3710,
        "PSA": 65,
        "RAPSA": 695000,
        "EstimatedRACurrent": 695000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3740,
        "PayoutAmount2": 3740,
        "PayoutAmount3": 3740,
        "PSA": 65,
        "RAPSA": 700000,
        "EstimatedRACurrent": 700000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3760,
        "PayoutAmount2": 3760,
        "PayoutAmount3": 3760,
        "PSA": 65,
        "RAPSA": 705000,
        "EstimatedRACurrent": 705000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3790,
        "PayoutAmount2": 3790,
        "PayoutAmount3": 3790,
        "PSA": 65,
        "RAPSA": 710000,
        "EstimatedRACurrent": 710000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3820,
        "PayoutAmount2": 3820,
        "PayoutAmount3": 3820,
        "PSA": 65,
        "RAPSA": 715000,
        "EstimatedRACurrent": 715000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3840,
        "PayoutAmount2": 3840,
        "PayoutAmount3": 3840,
        "PSA": 65,
        "RAPSA": 720000,
        "EstimatedRACurrent": 720000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3870,
        "PayoutAmount2": 3870,
        "PayoutAmount3": 3870,
        "PSA": 65,
        "RAPSA": 725000,
        "EstimatedRACurrent": 725000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3890,
        "PayoutAmount2": 3890,
        "PayoutAmount3": 3890,
        "PSA": 65,
        "RAPSA": 730000,
        "EstimatedRACurrent": 730000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3920,
        "PayoutAmount2": 3920,
        "PayoutAmount3": 3920,
        "PSA": 65,
        "RAPSA": 735000,
        "EstimatedRACurrent": 735000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3950,
        "PayoutAmount2": 3950,
        "PayoutAmount3": 3950,
        "PSA": 65,
        "RAPSA": 740000,
        "EstimatedRACurrent": 740000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 3970,
        "PayoutAmount2": 3970,
        "PayoutAmount3": 3970,
        "PSA": 65,
        "RAPSA": 745000,
        "EstimatedRACurrent": 745000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4000,
        "PayoutAmount2": 4000,
        "PayoutAmount3": 4000,
        "PSA": 65,
        "RAPSA": 750000,
        "EstimatedRACurrent": 750000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4020,
        "PayoutAmount2": 4020,
        "PayoutAmount3": 4020,
        "PSA": 65,
        "RAPSA": 755000,
        "EstimatedRACurrent": 755000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4050,
        "PayoutAmount2": 4050,
        "PayoutAmount3": 4050,
        "PSA": 65,
        "RAPSA": 760000,
        "EstimatedRACurrent": 760000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4080,
        "PayoutAmount2": 4080,
        "PayoutAmount3": 4080,
        "PSA": 65,
        "RAPSA": 765000,
        "EstimatedRACurrent": 765000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4100,
        "PayoutAmount2": 4100,
        "PayoutAmount3": 4100,
        "PSA": 65,
        "RAPSA": 770000,
        "EstimatedRACurrent": 770000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4130,
        "PayoutAmount2": 4130,
        "PayoutAmount3": 4130,
        "PSA": 65,
        "RAPSA": 775000,
        "EstimatedRACurrent": 775000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4160,
        "PayoutAmount2": 4160,
        "PayoutAmount3": 4160,
        "PSA": 65,
        "RAPSA": 780000,
        "EstimatedRACurrent": 780000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4180,
        "PayoutAmount2": 4180,
        "PayoutAmount3": 4180,
        "PSA": 65,
        "RAPSA": 785000,
        "EstimatedRACurrent": 785000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4210,
        "PayoutAmount2": 4210,
        "PayoutAmount3": 4210,
        "PSA": 65,
        "RAPSA": 790000,
        "EstimatedRACurrent": 790000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4230,
        "PayoutAmount2": 4230,
        "PayoutAmount3": 4230,
        "PSA": 65,
        "RAPSA": 795000,
        "EstimatedRACurrent": 795000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }, {
        "PayoutAge1": 65,
        "PayoutAge2": 85,
        "PayoutAge3": 95,
        "PayoutAmount1": 4260,
        "PayoutAmount2": 4260,
        "PayoutAmount3": 4260,
        "PSA": 65,
        "RAPSA": 800000,
        "EstimatedRACurrent": 800000,
        "ServiceInformation": {
            "ApplicationName": 0,
            "CurrentStepNumber": 0,
            "Description": "-",
            "FormCompletionTime": null,
            "ImportantNotes": "-",
            "ImportantNotesVisibility": false,
            "IsTermsAndConditionsChecked": false,
            "ModuleName": "",
            "Prerequisite": "-",
            "PrerequisiteVisibility": false,
            "RateThisService": {
                "RateThisServiceLink": "~\\OnlinePoll\\RateThisService"
            },
            "ResourceFileInstance": null,
            "Steps": [],
            "TermType": 1,
            "DisclaimerType": 0,
            "TermsAndConditions": "-",
            "TermsAndConditionsAcceptanceText": "I have read and accepted the Terms \u0026 Conditions above.",
            "TermsAndConditionsAcceptanceVisibility": false,
            "TermsAndConditionsHeaderText": "Terms \u0026 Conditions",
            "TermsAndConditionsVisibility": false,
            "SelectedUseRequestDetails": false,
            "SelectedUseRequestDetailsVisibility": false,
            "MyInfoNotesVisibility": false,
            "AdditionalInformation": null
        },
        "CalculateButtonText": "Calculate",
        "ResultText": "Results",
        "DataContext": null,
        "RequestContext": {
            "RequestContextId": "c1766d00-571f-40c8-a77b-51dc2585cad4"
        },
        "UserContext": {
            "IPAddress": "42.60.160.211",
            "SessionId": "kdqyz0bmjhhhdjzf4u4qbs1x",
            "UserId": -1,
            "UserName": "Anonymous",
            "CpfAccountNumber": null,
            "MasterMemberId": 0,
            "Birthdate": "\/Date(-62135596800000)\/",
            "Age": 0,
            "Sex": null
        }
    }];