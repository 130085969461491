import './App.css';
import React, {useState} from 'react';
import {Button, Form, InputNumber, Radio, Table} from "antd";
import {useForm} from "antd/es/form/Form";
import {generate_result} from "./results_generator";

const App = () => {

    const [balanceError, setBalanceError] = useState("");
    const [form] = useForm();
    const [plan, setPlan] = useState("standard");
    const [result, setResult] = useState({});

    const genderOptions = [
        {label: "Male", value: "male"},
        {label: "Female", value: "female"}
    ]

    const planOptions = [
        {label: "Standard Plan", value: "standard"},
        {label: "Basic Plan", value: "basic"},
        {label: "Escalating Plan", value: "escalating"}
    ];

    const columns = [
        {
            title: "Age",
            dataIndex: "age",
            key: "age",
            align: "center",
            fixed: true,
            width: 60,

        },
        {
            title: "Monthly Payout",
            dataIndex: "payout",
            key: "payout",
            align: "center",
            render: value => Math.round(value)
        },
        {
            title: "Total Payout",
            dataIndex: "total_payout",
            key: "total_payout",
            align: "center",
            render: value => Math.round(value)
        },
        {
            title: "Bequest",
            dataIndex: "bequest",
            key: "bequest",
            align: "center",
            render: value => Math.round(value)
        },
        {
            title: "Total Payout + Bequest",
            dataIndex: "total",
            key: "total",
            align: "center",
            render: value => Math.round(value)
        },
    ];

    const submitForm = () => {

        const gender = form.getFieldValue("gender");
        let balance = form.getFieldValue("balance");

        // console.log(gender);
        // console.log(balance);
        // console.log(plan);

        if (balance === undefined || balance === null) {
            setBalanceError("Enter your projected Retirement Account (RA) balance at 65 years old.")
            return;
        }

        if (balance < 40000 || balance > 800000) {
            setBalanceError("Balance must be between $40,000 and $800,000");
            return;
        }

        setBalanceError("");

        balance = Math.round(balance / 5000) * 5000;

        form.setFieldValue("balance", balance);
        setPlan(plan);

        setResult(generate_result(gender, balance));
    }

    return <div className="App">
        <h1>CPF Life Bequest Estimator</h1>
        <Form
            form={form}
            className="form"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
        >
            <Form.Item
                label="Gender"
                name="gender"
                initialValue="male"
            >
                <Radio.Group
                    name="gender"
                    options={genderOptions}
                    optionType="button"
                    buttonStyle="solid"
                />
            </Form.Item>
            <Form.Item
                label="RA balance @ 65"
                name="balance"
                validateStatus={balanceError === "" ? "": "error"}
                help={balanceError}
            >
                <InputNumber
                    name="balance"
                    className="input-field"
                    addonBefore="$"
                    step={5000}
                    min={40000}
                    max={800000}
                    onStep={value => form.setFieldValue("balance", Math.round(value / 5000) * 5000)}
                />
            </Form.Item>
            <Form.Item
                wrapperCol={{offset: 8, span: 16}}
                className="update-button"
            >
                <Button type="primary"
                        htmlType="submit"
                        onClick={submitForm}
                >
                    Update
                </Button>
            </Form.Item>
        </Form>
        <div className="results">
            <Radio.Group
                options={planOptions}
                defaultValue="standard"
                optionType="button"
                buttonStyle="solid"
                onChange={e => setPlan(e.target.value)}
            />
            <Table
                scroll={{x: 650, y: 500}}
                columns={columns}
                dataSource={result[plan]}
                pagination={false}
            />
        </div>
        <div className="disclaimer">
            <p>&copy; 2023 CPF Life Bequest Estimator. All rights reserved.</p>
            <p>This website has no affiliation with CPF Board.</p>
            <p>All values illustrated on this website are derived from the CPF Life Estimator and other public available information published on CPF Board's website.</p>
            <p>All payout and bequest values are estimated or interpolated as the CPF Life Estimator only provides payout values for ages 65, 85 and 95.</p>
            <p>This website does not guarantee the accuracy of any information displayed, and hereby disclaims liability for any loss due to use of this website.</p>
            <p>DO NOT make any decision on CPF and CPF Life based on the information on this website. Always consult CPF Board or a qualified financial adviser.</p>
        </div>
    </div>
}

export default App;
